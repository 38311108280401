import React from 'react';
import "./AccordionItem.css";
import plus from "../../../../Assets/images/svg/plus.svg" 
import dash from "../../../../Assets/images/svg/dash.svg" 

function AccordionItem({ title, content, index, isExpanded, onClick }) {
  const handleClick = () => {
    onClick(index);
  };
  

  return (
    <div className="accordion-item">
      <div
        className={`accordion-title ${isExpanded ? 'expanded' : ''}`}
        onClick={handleClick}
      >
        <span className="accordion-icon">{isExpanded ?  <img src={dash} /> : <img src={plus} /> }</span>
        {title}
      </div>
      {isExpanded && <div className="accordion-content">{content}</div>}
    </div>
  );
}

export default AccordionItem;