import React from 'react'
import "./About.css"
import { Container, Row, Col } from "react-bootstrap"
import PolarisIMG from '../../../../Assets/images/Home/hero.png'; 

const About = ({url, aboutSection}) => {

    const { who_polaris_title, who_polaris_description, why_polaries_title, why_polaries_description,
        our_mission_title, our_mission_description, about_polaries_img

    }= aboutSection.data;

    return (
        <Container >
            <div className='about-container'>
                <Row>
                    <Col md={12} lg={6}>
                        <div className='polaris-div'>
                            <div className='polaris-title' data-aos="fade-down" data-aos-duration="1500">{who_polaris_title}</div>
                            <div className='polaris-text' data-aos="fade-down" data-aos-duration="1800">{who_polaris_description}</div>
                        </div>
                        <div className='polaris-div'>
                            <div className='polaris-title' data-aos="fade-down" data-aos-duration="1500">{why_polaries_title}</div>
                            <div className='polaris-text' data-aos="fade-down" data-aos-duration="1800">{why_polaries_description}</div>
                        </div>
                        <div className='polaris-div'>
                            <div className='polaris-title' data-aos="fade-down" data-aos-duration="1500">{our_mission_title}</div>
                            <div className='polaris-text' data-aos="fade-down" data-aos-duration="1800">{our_mission_description}</div>
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className='polaris-img-div' data-aos='fade-down' data-aos-duration="1500">
                            <img src={url+"/"+ about_polaries_img} alt="PolarisIMG" className='polaris-img'/>
                        </div>
                    
                    </Col>
                </Row>

            </div>

            

        </Container>
    )
}

export default About
