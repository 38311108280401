import React from 'react'
import "./Footer.css"
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from "../../Assets/images/svg/logo-dark.svg"
import location from "../../Assets/images/svg/location.svg"
import facebook from "../../Assets/images/footer/facebook.svg"
import instagram from "../../Assets/images/footer/instagram.svg"
import linkedin from "../../Assets/images/footer/linkedin.svg"
import twitter from "../../Assets/images/footer/twitter.svg"

const Footer = ({ }) => {
  return (
    <footer className="mt-5">
      <Container>
        <div className="footer-content">
          <div className="footer-text" id="footer-logo">
            <a href="/home"> <img src={logo} /></a>
            {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum 
            </p> */}
          </div>
          <div className="footer-text" id="footer-links">
            <h3>Learn More</h3>
            <a href="/about">About us</a>
            <a href="/ourteam" className="">Our Team</a>
            <a href={"/contact"}>Contact us</a>
          </div>
          <div className="footer-text">
            <h3 className="">Website map</h3>
            <a href="/pricing">
              Pricing
            </a>
          </div>
          <div className="footer-text">
            <h3 className="text-uppercase">support</h3>
            {/* <p>Terms & conditions</p> */}
            <a href="/privacy">Privacy policy</a>
          </div>
          <div className="footer-text" id="footer-touch">
            <h3>get in touch</h3>
            <div className="footer-touch-text">
              <img className="footer-icon" src={location} />
              <p>Canada</p>
            </div>
            <div className="footer-touch-email">
              {/* <h2>Email</h2>
              <input type="email" placeholder="Your Email" /> */}
            </div>
            <a href="/bookdemo">Book Demo</a>
          </div>
        </div>
        <div className="footer-down">
          <div className="footer-links">
            <div className="links-text">
              <a href="/privacy">Privacy Policy</a>
              {/* <Link to="/">Terms and Conditions</Link> */}
            </div>

            <div className="links">
              {/* <img src={twitter} />
              <img src={linkedin} />
              <img src={facebook} />
              <img src={instagram} /> */}
            </div>
          </div>
          <div className="footer-down-para">
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, fuga. Ex at maxime eum odio quibusdam pariatur expedita explicabo harum! Consectetur ducimus delectus nemo, totam odit!</p> */}
            <p>Copyright @ 2024. POLARIS</p>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer