import React, { useState } from 'react';
import './ReminderPopUp.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import global from '../../assets/images/svg/global.svg';
import DatePickers from '../DatePickers/DatePickers';
import { DateTimePickerValue } from '../index';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';


const ReminderPopUp = ({ count, isOpen, onCancel, url, docName, onRefresh,dateTimePickerRef }) => {

  const [value, setValue] = useState(null);
  const handleDateTimeChange = (newValue) => {
    setValue(newValue);
    setErrors({}); // Reset errors when date changes
  }; 
  const handleCancel = () => {
    onCancel();
  };

  const [reminderText, setReminderText] = useState("");
  const [reminderDate, setReminderDate] = useState("");
  const [errors, setErrors] = useState({}); // Error state object

  const handleReminderDateChange = (date) => {
    setReminderDate(date);
    setErrors({}); // Reset errors when date changes
  };

  const handleSave = () => {
    if (!value) {
      setErrors({ date: "Please choose a date" });
      return;
    }
  
    if (!reminderText) {
      setErrors({ text: "Please enter a reminder title" }); // Set error for reminder text field
      return;
    }
 
   const currentDateTime = new Date();
  
    if (value < currentDateTime) {
      setErrors({ date: "Please choose a date after the current date" }); // Set error for date field
      return;
    }

     const pathSegments = window.location.pathname.split('/');
     const lastSegment = pathSegments[pathSegments.length - 1];
     let reminderDoctype;
     if (["regulation", "amendments","search","dashboard"].includes(lastSegment)  || window.location.pathname.includes('/dashboard/reports/')  || window.location.pathname.includes('/dashboard/regulation/'))  {
       reminderDoctype = "Federal Regulation";
     } 


    const data = {
      remind_at: value.format('YYYY-MM-DD HH:mm:ss'), 
      description: reminderText,
      reminder_doctype: reminderDoctype,
      reminder_docname: `${docName}`
    };
  
    const apiUrl = `${url}/api/method/frappe.automation.doctype.reminder.reminder.reminder`;
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');
  
    axios
      .post(
        apiUrl,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${apiKey}:${apiSecret}`
          },
        }
      )
      .then(response => {
        onCancel(); // Close the component after saving
        const formattedReminderDate = data.remind_at.toLocaleString();
        toast.success(`You saved a reminder for ${formattedReminderDate}`);
        setReminderText("");
        setReminderDate("");
        if(onRefresh){
          onRefresh();
        }
      })
      .catch(error => {
        console.error(error);
        toast.error('Failed to save the reminder.');
      });
  };

  return (
    <div className={`reminder-pop-up ${isOpen ? "active" : ""}`}>
      <div className="reminder-header">
        <div className="global-reminders">
          <img className="reminder-header-image" src={global} alt="Global" />
          <h1 className="reminder-header-title">Add Reminder</h1>
          {/* <h1 className="reminder-header-count">{count}</h1> */}
        </div>
        <Link reloadDocument to="/dashboard/reminders" className="see-all-reminders">
          <h1 className="see-all-reminders-title">See All reminders</h1>
        </Link>
      </div>
      <div className="reminder-text">
        <input
          type="text"
          placeholder="Reminder title"
          className="reminder-title-text"
          value={reminderText}
          onChange={(e) => setReminderText(e.target.value)}
        />
        {errors.text && <p className="popup-error">{errors.text}</p>}{" "}
        {/* Display error for text field */}
        {/* <DatePickers onDateChange={handleReminderDateChange} inputType="date" /> */}   
        <DateTimePickerValue
          handleDateTimeChange={handleDateTimeChange} 
          value={value} 
          ref={dateTimePickerRef}
        />
        {errors.date && <p className="popup-error">{errors.date}</p>}{" "}
        {/* Display error for date field */}
      </div>
      <div className="reminder-footer">
        <div>
          {/* <button className="reminder-delete-btn">Delete</button> */}
        </div>
        <div>
          <button className="reminder-cancel-btn" onClick={handleCancel}>
            Cancel
          </button>
          <button className="reminder-save-btn" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReminderPopUp;
