import React, { useEffect,useState } from 'react'
import "./Amendments.css"
import { Title, DatePickers, Text, SelectFilter, UnderDevelopment } from "../../Components"
import { Form } from 'react-bootstrap'
import { Results } from "../../Features/Amendments"
import titleImg from "../../assets/images/svg/amendments.svg"
import Modal from 'react-modal';
 
const Amendments = ({ url, dashboardData, selectedFilters, setSelectedFilters,searchQuery,setSearchQuery,callChangeReportFilters, reportObject,loading }) => {
  const [isClear, setIsClear] = useState(false);
  const amendmentSection = dashboardData?.find(
    (section) => section.section === "Amendment List"
  );
    const handleResetDate = () => {
        setSelectedDate(null); // Reset the selected date
        setIsClear(true);
      };

      const [selectedDate, setSelectedDate] = useState({ startDate: null, endDate: null });

      const handleDateChange = (date) => {  
          if (!date || !date.startDate || !date.endDate) {
              // Handle the case where date or startDate or endDate is null
              return;
       }
        
          // Function to format date as "Y-%m-%d %H:%M:%S"
          const formatDate = (inputDate) => {
            if (!inputDate) return null;
            const year = inputDate.getFullYear();
            const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const day = inputDate.getDate().toString().padStart(2, '0');
        
            return `${year}-${month}-${day}`;
          };
        
          // Set time to midnight for the start date and format it
          const startDateTime = date.startDate ? formatDate(new Date(date.startDate)) : null;
        
          // Set time to midnight for the end date and format it
          const endDateTime = date.endDate ? formatDate(new Date(date.endDate)) : null;
        
          setSelectedDate({
            startDate: startDateTime,
            endDate: endDateTime
          });
        };
      
      useEffect(() => {
        }, [selectedDate]);

    const [showPopup, setShowPopup] = useState(false)

    const togglePopup = () => {
        setShowPopup(!showPopup);
    }

    return (
      <div className="innerpages-container">
        <Title
          title={"Amendments"}
          showInfo={false}
          icon={titleImg}
          titleBtn={true}
          onDateChange={handleDateChange}
          onReset={handleResetDate}
          url={url}
          dashboardData={dashboardData}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          reportObject={reportObject}
          callChangeReportFilters={callChangeReportFilters}
          loading={loading}


        />
      <p className="innerpages-para" dangerouslySetInnerHTML={{ __html:  amendmentSection?.data?.page_description??""}}>
      </p>
        <div className="amendments-info">
          <div className="hide-date-responsive">
            <Text para={"Filter by data range:"} />
            <div className="mt-2" >
              <DatePickers
                inputType={"datetime"}
                onDateChange={handleDateChange}
                onReset={handleResetDate}
              />
            </div>
          </div>
          {/* <div className='hide-responsive'>
                    <Text para={"Filter by jurisdictions"} />
                    <div className='mt-2' onClick={togglePopup}>
                        <SelectFilter />
                    </div>
                </div> */}
          {/* <Form className='amendments-checkboxes'>
                    <Form.Check 
                        onClick={togglePopup}
                        type="switch"
                        id="upcoming-amendments"
                        label="Upcoming Amendments"
                    />
                    <Form.Check 
                        onClick={togglePopup}
                        type="switch"
                        id="force-amendments"
                        label="Amendments in Force"
                    />
                </Form> */}
        </div>
        <Results
          url={url}
          selectedFilters={selectedFilters}
          selectedDate={selectedDate}
          searchQuery={searchQuery}
          dashboardData={dashboardData}
          clearedDate={isClear}
        />
        <Modal
          isOpen={showPopup}
          onRequestClose={togglePopup}
          contentLabel="Select Filter Popup"
        >
          <UnderDevelopment btnClick={togglePopup} />
        </Modal>
      </div>
    );
}

export default Amendments
