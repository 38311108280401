import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Row, Col, Container } from 'react-bootstrap';
import AOS from 'aos';
import axios from 'axios';
import Cookies from 'js-cookie';
import 'aos/dist/aos.css';
import { AiBtn, ErrorComponent } from './Dashboard/Components';
import { SideBar, NavPages } from './Dashboard/Layouts';
import {
  Dashboard, Search, Amendments, Reminders, RegulationDetails, Notes, Regulation,
  ReportsPage, ReportsDetails, ProfilePage, ChatBot
} from './Dashboard/Pages';
import { Login, Register, CheckEmail, EmailVerified, ResetPassword } from './Dashboard/Auth';
import favIcon from "./Website/Assets/images/svg/logo-dark.svg";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "./Dashboard/assets/images/svg/logo.svg";

// Website imports 
import { Home, Privacy, Pricing, PaymentPage, AboutPage, Contact, OurTeam, BookDemo, Trail } from "./Website/Pages";
import { MainNavbar, Footer } from "./Website/Layouts";
import { ScrollTop } from './Website/Components';

function DashboardLayout({ url, children, dashboardData, selectedFilters, setSelectedFilters, searchQuery, setSearchQuery,regulationSearchQuery,setRegulationSearchQuery, reportObject, callChangeReportFilters, loading, notificationsData }) {
  const location = useLocation();
  const shouldRenderSidebar = location.pathname !== '/dashboard/profile';
  const isProfilePath = location.pathname === '/dashboard/profile';

  return (
    <Row className={` ${isProfilePath ? 'profile-page' : ''}`}>
      {shouldRenderSidebar && (
        <Col xl={2} xs={2} className="sidebar-container">
          <SideBar
            url={url}
            data={dashboardData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            callChangeReportFilters={callChangeReportFilters}
            reportObject={reportObject}
            loading={loading}
          />
        </Col>
      )}
      <Col xl={10} className={` ${isProfilePath ? ' container profile-container' : 'content-container'}`}>
        <NavPages url={url} notificationsData={notificationsData}  setSearchQuery={setSearchQuery} searchQuery={searchQuery}/>
        <div className='ai-btn-container'>
          <AiBtn />
        </div>
        <Container className="content">
          {children}
        </Container>
      </Col>
    </Row>
  );
}

function WesiteLayout({ url, children, loading }) {
  const location = useLocation();
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    setInitialLoading(false);
  }, []);

  if (initialLoading || loading) {
    return (
      <div className='loading'>
       <svg className="star" width="128" height="128" viewBox="0 0 128 128">
      <defs>
      <filter id="star-glow">
      <feGaussianBlur result="coloredBlur" stdDeviation="1.5"></feGaussianBlur>
      <feMerge>
      <feMergeNode in="coloredBlur"></feMergeNode>
      <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
      </filter>
      <linearGradient id="star-grad" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stop-color="#000" />
      <stop offset="100%" stop-color="#fff" />
      </linearGradient>
      <mask id="star-mask">
      <rect x="0" y="0" width="128" height="128" fill="url(#star-grad)" />
      </mask>
      </defs>
      <g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
      <g stroke="hsla(223,90%,50%,0.2)">
      <polygon points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"/>
      <polygon points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"/>
      <polygon points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"/>
      <polygon points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"/>
      </g>
      <g filter="url(#star-glow)">
      <g stroke="#2DB0D8">
      <polygon className="star__stroke" stroke-dasharray="31 93" points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"/>
      <polygon className="star__stroke star__stroke--2" stroke-dasharray="62 186" points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"/>
      <polygon className="star__stroke star__stroke--3" stroke-dasharray="93 279" points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"/>
      <polygon className="star__stroke star__stroke--4" stroke-dasharray="124 372" points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"/>
      </g>
      <g stroke="#2DB0D8" mask="url(#star-mask)">
      <polygon className="star__stroke" stroke-dasharray="31 93" points="64 49 66.322 58.992 71.071 56.929 69.008 61.678 79 64 69.008 66.322 71.071 71.071 66.322 69.008 64 79 61.678 69.008 56.929 71.071 58.992 66.322 49 64 58.992 61.678 56.929 56.929 61.678 58.992 64 49"/>
      <polygon className="star__stroke star__stroke--2" stroke-dasharray="62 186" points="64 34 68.644 53.983 78.142 49.858 74.017 59.356 94 64 74.017 68.644 78.142 78.142 68.644 74.017 64 94 59.356 74.017 49.858 78.142 53.983 68.644 34 64 53.983 59.356 49.858 49.858 59.356 53.983 64 34"/>
      <polygon className="star__stroke star__stroke--3" stroke-dasharray="93 279" points="64 19 70.966 48.975 85.213 42.787 79.025 57.034 109 64 79.025 70.966 85.213 85.213 70.966 79.025 64 109 57.034 79.025 42.787 85.213 48.975 70.966 19 64 48.975 57.034 42.787 42.787 57.034 48.975 64 19"/>
      <polygon className="star__stroke star__stroke--4" stroke-dasharray="124 372" points="64 4 73.287 43.966 92.284 35.716 84.034 54.713 124 64 84.034 73.287 92.284 92.284 73.287 84.034 64 124 54.713 84.034 35.716 92.284 43.966 73.287 4 64 43.966 54.713 35.716 35.716 54.713 43.966 64 4"/>
      </g>
      </g>
      </g>
      </svg>
      </div>
    );
  }

  return (
    <>
      <MainNavbar url={url} />
      {children}
      <Footer />
    </>
  );
}

function App() {
  const url = 'https://dashboard.polarisai.ca';
  const [data, setData] = useState([]);
  const [verifyData, setVerifyData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [pageTitle, setPageTile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [regulationSearchQuery, setRegulationSearchQuery] = useState('');
  const [reportObject, setReportObject] = useState({});
  const [callChangeReportFilters, setCallChangeReportFilters] = useState(async () => { });
  const [notificationsData, setNotificationsData] = useState([]);
  const pageUrl = window.location.pathname;
  let pageName;

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    const faviconLink = document.querySelector('link[rel="shortcut icon"]');
    if (faviconLink) {
      faviconLink.href = favIcon;
    } else {
      const newFaviconLink = document.createElement('link');
      newFaviconLink.rel = 'shortcut icon';
      newFaviconLink.href = favIcon;
      document.head.appendChild(newFaviconLink);
    }
  }, []);

  const clearCookies = () => {
    const cookies = document.cookie.split(";");

    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
  };

  const fetchWebsiteData = async (pageName) => {
    setLoading(true);
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');
    var headers = { 'Content-Type': 'application/json', };

    if (apiKey == null && apiSecret == null) {
      clearCookies();
    } else {
      headers["Authorization"] = `token ${apiKey}:${apiSecret}`;
    }

    try {
      const response = await axios.get(
        `${url}/api/method/projectx.projectx.doctype.pages.pages.page?name=${pageName}`,
        { headers: headers }
      );

      if (response?.data?.result?.is_active === false) {
        clearCookies();
      }
      setData(response?.data?.result?.content?.templates || []);
      setPageTile(response?.data?.result?.page_title || "");
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!window.location.pathname.startsWith('/dashboard')) {
      if ((pageUrl.lastIndexOf("/") === -1) || (pageUrl.lastIndexOf("/") === pageUrl.length - 1)) {
        pageName = "home";
      } else {
        pageName = pageUrl.substr(pageUrl.lastIndexOf("/") + 1);
      }
      fetchWebsiteData(pageName);
    }
  }, [pageUrl]);

  const getPageNameFromUrl = () => {
    const pathArray = window.location.pathname.split('/');
    return pathArray[pathArray.length - 1];
  };

  const fetchDashboardData = async (pagename, regulationName = null) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const searchQuery = urlParams.get('search');


    setDashboardLoading(true);
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');
  
    if (!apiKey || !apiSecret) {
      clearCookies();
      window.location.href = '/login';
      return;
    }
  
    let apiUrl = `${url}/api/method/projectx.projectx.doctype.pages.pages.page?name=${pagename}`;

    // if (regulationSearchQuery) {
    //   apiUrl += `&search=${regulationSearchQuery}`;
    // }
    if (searchQuery) {
      apiUrl += `&search=${searchQuery}`;
      setSearchQuery(searchQuery)
    }

    if (regulationName) {
      apiUrl += `&regulation=${regulationName}`;
    }
  
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });
  
      if (response?.data?.result?.is_active) {
        setDashboardData(response?.data?.result?.content?.templates || []);
        setNotificationsData(response?.data?.result?.notifications?.notification_logs || []);
        setTimeout(() => {
          setDashboardLoading(false);
        }, 3000);
      } else {
        setDashboardLoading(false);
        clearCookies();
        window.location.href = '/login';
      }
      setPageTile(response?.data?.result?.page_title || "");
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
      setDashboardLoading(false);
    }
  };
  
  useEffect(() => {
    if (window.location.pathname.startsWith('/dashboard')) {
  
      let pagename = getPageNameFromUrl();
  
      if (window.location.pathname.startsWith('/dashboard/reports/')) {
        pagename = "reports";
      }
  
      const isRegulationDetailsPage = /^\/dashboard\/regulation\/[^\/]+$/.test(window.location.pathname);
      const isChatbotPage = window.location.pathname.endsWith('/chatbot');
      const isAmendmentsDetailsPage = /^\/dashboard\/amendments\/[^\/]+$/.test(window.location.pathname);
  
      if (!isChatbotPage) {
        if (isRegulationDetailsPage) {
          const regulationName = window.location.pathname.split('/').pop();
          fetchDashboardData('regulation details', regulationName);
        } else if (isAmendmentsDetailsPage) {
          const regulationName = window.location.pathname.split('/').pop();
          fetchDashboardData('amendments', regulationName);
        } else {
          fetchDashboardData(pagename);
        }
      }
    }
  }, []);
  
  
  


  useEffect(() => {
    const pageName = window.location.pathname.split('/').pop();
    if (pageName === "chatbot") {
      document.title = `Polaris | ChatBot`;
    } else {
      document.title = `Polaris | ${pageTitle}`;
    }
  }, [pageTitle]);

  const isDashboardRoute = window.location.pathname.startsWith('/dashboard');

  return (
    <BrowserRouter>
      <div className="App">
        <ToastContainer />
        <ScrollTop />

        {error ? (
          isDashboardRoute ? (
            <DashboardLayout
              url={url}
              dashboardData={dashboardData}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              reportObject={reportObject}
              callChangeReportFilters={callChangeReportFilters}
              loading={dashboardLoading}
              notificationsData={notificationsData}
            >
              <ErrorComponent />
            </DashboardLayout>
          ) : (
            <WesiteLayout url={url} loading={loading}>
              <ErrorComponent />
            </WesiteLayout>
          )
        ) : (
          <Routes>
            {isDashboardRoute ? (
              <Route
                path="/dashboard/*"
                element={
                  <DashboardLayout
                    url={url}
                    dashboardData={dashboardData}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    searchQuery={searchQuery}
                    regulationSearchQuery={regulationSearchQuery}
                    setSearchQuery={setSearchQuery}
                    setRegulationSearchQuery={setRegulationSearchQuery}
                    reportObject={reportObject}
                    callChangeReportFilters={callChangeReportFilters}
                    loading={dashboardLoading}
                    notificationsData={notificationsData}
                  >
                  <Routes>
                    <Route path="/" element={<Dashboard url={url} dashboardData={dashboardData} />} />
                    <Route path="/dashboard" element={<Dashboard url={url} dashboardData={dashboardData} />} />
                    <Route path="/search" element={<Search url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} callChangeReportFilters={callChangeReportFilters} setCallChangeReportFilters={setCallChangeReportFilters} reportObject={reportObject} setReportObject={setReportObject}  />} />
                    <Route path="/amendments" element={<Amendments url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} setSearchQuery={setSearchQuery}
                      callChangeReportFilters={callChangeReportFilters}
                      reportObject={reportObject} />} />
                    <Route path="/amendments/:name" element={<Amendments url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} searchQuery={searchQuery} />} />
                    <Route path="/reminders" element={<Reminders url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters}  searchQuery={searchQuery}/>} />
                    <Route path="/regulation" element={<Regulation url={url} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery}  setSearchQuery={setSearchQuery}  dashboardData={dashboardData} callChangeReportFilters={callChangeReportFilters} reportObject={reportObject} />} />
                    <Route path="/reports" element={<ReportsPage url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} setSearchQuery={setSearchQuery} callChangeReportFilters={callChangeReportFilters} reportObject={reportObject} />} />
                    <Route path="/reports/:name" element={<ReportsDetails url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} setSearchQuery={setSearchQuery} callChangeReportFilters={callChangeReportFilters} setCallChangeReportFilters={setCallChangeReportFilters} reportObject={reportObject} setReportObject={setReportObject} />} />
                    <Route path="/notes" element={<Notes url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} searchQuery={searchQuery} setSearchQuery={setSearchQuery} callChangeReportFilters={callChangeReportFilters} setCallChangeReportFilters={setCallChangeReportFilters} reportObject={reportObject} setReportObject={setReportObject} />} />
                    <Route path="/regulation/:name" element={<RegulationDetails url={url} dashboardData={dashboardData} selectedFilters={selectedFilters} searchQuery={searchQuery} />} />
                    <Route path="/profile" element={<ProfilePage url={url} dashboardData={dashboardData} />} />
                  </Routes>
                </DashboardLayout>
                
                }
              />
            ) : (
              <Route
                path="/*"
                element={
                <WesiteLayout url={url} loading={loading}>
                  <Routes>
                    <Route path="/" element={<Home url={url} data={data} />} />
                    <Route path="home" element={<Home url={url} data={data} />} />
                    <Route path="privacy" element={<Privacy data={data} />} />
                    <Route path="pricing" element={<Pricing url={url} data={data} />} />
                    <Route path="payment" element={<PaymentPage />} />
                    <Route path="about" element={<AboutPage url={url} data={data} />} />
                    <Route path="contact" element={<Contact url={url} data={data} />} />
                    <Route path="ourteam" element={<OurTeam url={url} data={data} />} />
                    <Route path='bookdemo' element={<BookDemo url={url} data={data} />} />
                    <Route path='freetrail' element={<Trail url={url} data={data} />} />
                    <Route path="/login" element={<Login url={url} />} />
                    <Route path='/register' element={<Register url={url} data={data} />} />
                    <Route path="/checkemail" element={<CheckEmail />} />
                    <Route path="/emailverified" element={<EmailVerified />} />
                    <Route path="/resetpassword/:email/:code" element={<ResetPassword url={url} />} />
                  </Routes>
                </WesiteLayout>
                }
              />
            )}

            <Route path="/dashboard/chatbot" element={<ChatBot url={url} />} />

          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
