import React, { useState, useRef } from 'react';
import "./AddEmployeeModal.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import Cookies from 'js-cookie';
import Camera from "../../../../assets/images/svg/camera-icon.svg";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ccc', // Default border color
    },
    '&:hover fieldset': {
      borderColor: 'var(--main-color)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--main-color)', // Border color when focused
    },
  },
  '& .MuiInputLabel-root': {
    marginTop: "-5px",
    fontSize: '14px',
    fontFamily: "Poppins400"
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: "var(--main-color)"
  },
});

export default function AddEmployeeModal({ url, open, handleClose, handleSave,fetchData }) {
  const [employeeData, setEmployeeData] = useState({
    firstName: '',
    lastName: '',
    province: '',
    userImage: '',
    email: ''
  });
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState(require("../../../../assets/images/dashboard/Mask group.png")); // Default image
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileBase64(reader.result);
        setImagePreviewUrl(reader.result); // Update the image preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = async () => {
    const dataToSubmit = {
      first_name: employeeData.firstName,
      last_name: employeeData.lastName,
      email: employeeData.email,
      role: "Company Employee",
      province: employeeData.province,
      user_image: fileBase64 ? {
        filename: file.name,
        data: fileBase64,
      } : null,
    };

    try {
      const apiKey = Cookies.get('api_key');
      const apiSecret = Cookies.get('api_secret');
      const response = await fetch(`${url}/api/method/erpnext.selling.doctype.customer.customer.employee`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
        body: JSON.stringify(dataToSubmit),
      });

      if (response.ok) {
        handleSave(employeeData);
        handleClose(); // Close the modal after successful save
        setEmployeeData({
          firstName: '',
          lastName: '',
          province: '',
          userImage: '',
          email: ''
        });
        fetchData(0,10);
      } else {
        console.error('Failed to save employee data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Modal
      className='add-employee-modal'
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" className='add-employee-title'>
          Add New Employee
        </Typography>
        <div
          className="col-12 profile-photo-div-parent"
          onClick={handleFileClick}
          onDrop={(event) => event.preventDefault()}
          onDragOver={(event) => event.preventDefault()}
        >
          <div className="profile-photo-div">
            <label className="custom-file-upload">
              <img src={imagePreviewUrl} className="profile-img" alt="Profile" />
              <img src={Camera} className="c1" alt="Camera Icon" />
            </label>
            <input
              id="select_files"
              type="file"
              ref={fileInputRef}
              accept="image/x-png,image/png,image/jpeg,image/jpg,image/svg+xml"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <CustomTextField
          fullWidth
          margin="normal"
          name="firstName"
          label="First Name"
          variant="outlined"
          value={employeeData.firstName}
          onChange={handleChange}
        />
        <CustomTextField
          fullWidth
          margin="normal"
          name="lastName"
          label="Last Name"
          variant="outlined"
          value={employeeData.lastName}
          onChange={handleChange}
        />
        <CustomTextField
          fullWidth
          margin="normal"
          name="email"
          label="Email"
          variant="outlined"
          value={employeeData.email}
          onChange={handleChange}
        />
        <Typography id="modal-province-title" className='second-add-employee-title'>
          Provinces
        </Typography>
        <div className='add-report-checks'>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Manitoba.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Northwest Territories.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Nova Scotia.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Manitoba.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Northwest Territories.</label>
          </div>
          <div className='add-report-content'>
            <input type='checkbox' className='add-report-checkbox' /><label className='add-report-label'>Nova Scotia.</label>
          </div>
        </div>
        <div className='add-employee-btns'>
          <Button className='add-employee-cancle' onClick={handleClose}>Cancel</Button>
          <Button className='add-employee-save' onClick={handleSubmit}>Save</Button>
        </div>
      </Box>
    </Modal>
  );
}
