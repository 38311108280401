import React from "react";
import "./HR.css";
import { Container, Row, Col } from "react-bootstrap";
import { Hr, LinkBtn } from "../../../../Components";
import arrowRight from "../../../../Assets/images/svg/arrow-right.svg";
import arrowLeft from "../../../../Assets/images/svg/arrow-left.svg";
import arrowRightDown from "../../../../Assets/images/svg/arrow-right-down.svg";
import arrowLeftDown from "../../../../Assets/images/svg/arrow-left-down.svg";
import RightStar from '../../../../Assets/images/Home/right-star.png';

const HR = ({ url, hrSection }) => {

  const { hr_title, hr_description, hr_title_1, hr_title_2, hr_title_3,
    hr_img_1, hr_img_2, hr_img_3, hr_book_btn, hr_about_btn } = hrSection.data;


  return (
    <div style={{ position: "relative" }}>
      <div className="right-star-div">
        <img src={RightStar} className="right-star-img"></img>
      </div>
      <Container className="hr-container" data-aos="fade-down" data-aos-duration="2000">
        <h1 className="hr-title">
          {/* Canadian <span>HR</span> compliance laws in your hands */}
          <p dangerouslySetInnerHTML={{ __html: hr_title }}></p>
        </h1>
        <p className="hr-para">
          {/* Streamline compliance, enhance efficiency, and navigate complex
          regulations effortlessly with our innovative solutions. Streamline
          compliance, enhance efficiency, and navig */}
          {hr_description}
        </p>

        <Row className="hr-components-container">
          <img
            src={arrowRight}
            id="arrow-right"
            data-aos="fade-down"
            data-aos-duration="1500"
          />
          <img
            src={arrowLeft}
            id="arrow-left"
            data-aos="fade-down"
            data-aos-duration="1500"
          />
          <Col lg={4}>
            <Hr
              title={hr_title_1}
              image={url + "/" + hr_img_1}
            />
          </Col>
          <Col lg={4} className="hr-links-container">
            <div className="hr-links">
            
              <LinkBtn
                bg={"var(--main-color)"}
                color={"#fff"}
                title={hr_book_btn}
                to = "/bookdemo"
              />
              <LinkBtn
                bg={"#fff"}
                color={"var(--main-color)"}
                title={hr_about_btn}
                to = "/about"
              />
              
            </div>
          </Col>
          <Col lg={4}>
            <Hr
              title={hr_title_2}
              image={url + "/" + hr_img_2}
            />
          </Col>
          <img
            src={arrowRightDown}
            id="arrow-right-down"
            data-aos="fade-left"
            data-aos-duration="1500"
          />
          <Col lg={4} className="mt-lg-5">
            <Hr
              title={hr_title_3}
              image={url + "/" + hr_img_3}
            />
          </Col>
          <img
            src={arrowLeftDown}
            id="arrow-left-down"
            data-aos="fade-right"
            data-aos-duration="1500"
          />
          <div className="hr-links-responsive">
            <LinkBtn
              bg={"var(--main-color)"}
              color={"#fff"}
              title={hr_book_btn}
            />
            <LinkBtn
              bg={"#fff"}
              color={"var(--main-color)"}
              title={hr_about_btn}
            />
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default HR;
