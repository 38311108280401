import React from 'react'
import "./Contact.css"
import { ContactUs, Hero } from "../../Components"

const Contact = ({url, data}) => {
  const contactSection = data?.find(item => item.section === "Contact Us Section");

  return (
    <div className='contact-page'>
      <Hero
        mainTitle={"Home /"}
        title={"Contact us"}
        para={"CONTACT US"}
      />
      <ContactUs url={url} contactSection={contactSection} />
    </div>
  )
}

export default Contact