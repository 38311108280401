import React from 'react'
import "./DeleteBtn.css"
import dash from "../../assets/images/svg/dash.svg"

const DeleteBtn = ({ count, name}) => {
    return (
        <div className='delete-btn'>
            <div className='delete-btn-img'>
                <img src={dash} />
            </div>
            <h1 className='delete-btn-title'>{name}</h1>
            {
                count && <h1 className='delete-btn-count'>({count})</h1>
            }
        </div>
    )
}

export default DeleteBtn
