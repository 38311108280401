import React, { useState, useEffect } from 'react';
import './Results.css';
import axios from 'axios';
import { Paginations, Debounce } from '../../../../Components';
import { Reports } from "../../index"
import Cookies from 'js-cookie';
import Skeleton from 'react-loading-skeleton';
import { toast } from "react-toastify";



const Results = ({ url, selectedFilters, dashboardData, selectedDate, clearedDate, searchQuery}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const resultsPerPage = 20;
  const debouncedSearchQuery = Debounce(searchQuery, 500); // 500ms debounce delay
  // useEffect(() => {
  //   fetchPageData(currentPage);
  // }, [currentPage]);


  useEffect(() => {
    // Extract the initial data from dashboardData
    const notesSection = dashboardData?.find(
      (section) => section.section === "Notes List"
    );
    if (notesSection) {
      const initialData = notesSection.data.content.notes.objects;
      const pagination = notesSection.data.content.notes.pagination.metadata;
      setData(initialData);
      setCurrentPage(pagination.current_page);
      setTotalPages(pagination.total_pages);
      setIsLoading(false);
    }
  }, [dashboardData,]);


  useEffect(() => {
    if ((selectedFilters != {} && selectedFilters != undefined && Object.keys(selectedFilters).length > 0) || (selectedDate && (selectedDate.startDate !== null || selectedDate.endDate !== null)) || (clearedDate && clearedDate === true) ) {
      fetchPageData();
    }
  }, [selectedFilters, selectedDate,clearedDate]);


  useEffect(() => {
    if (debouncedSearchQuery.length > 0) {
      fetchPageData();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (searchQuery === "" && debouncedSearchQuery.length > 0) {
      fetchPageData();
    }
  }, [searchQuery]);


  const fetchPageData = async (pageNumber=1) => {
    try {
      setIsLoading(true);
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
      const offset = (pageNumber - 1) * resultsPerPage;
      const filterParams = Object.keys(selectedFilters)
        .filter((type) => selectedFilters[type].length > 0)
        .map((type) => {
          if (Array.isArray(selectedFilters[type])) {
            return `${type}=${encodeURIComponent(
              selectedFilters[type].join(",")
            )}`;
          } else {
            return `${type}=${encodeURIComponent(selectedFilters[type])}`;
          }
        })
        .join("&");
      let apiUrl = `${url}/api/method/projectx.projectx.doctype.document_note.document_note.document_note?start=${offset}&limit=${resultsPerPage}${
        filterParams ? `&${filterParams}` : ""
      }${searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ""}`;
      if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
        apiUrl += `&start_date=${selectedDate.startDate}&end_date=${selectedDate.endDate}`;
      }
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });

      if (response.data.status_code !== 200) {
        toast.error("Failed to fetch data. Please try again..");
      }

      const result = response?.data?.result || {};
      setData(result.objects || []);
      setCurrentPage(result.pagination.metadata.current_page || 1);
      setTotalPages(result.pagination.metadata.total_pages || 1);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch data. Please try again..");
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const onPageChange = (pageNumber) => {
    fetchPageData(pageNumber);
  };

  const handleNoteDelete = () => {
    fetchPageData(currentPage);
  };

  return (
    <div className="results">
      {isLoading ? (
        <>
          <div className='notes-skeleton'>
            <div className="first-notes-skeleton">
              <div>
                <Skeleton height={18} width={"100%"} />
                <Skeleton height={15} width={"90%"} />
              </div>
              <div>
                <Skeleton height={15} width={"100%"} />
                <Skeleton height={13} width={"90%"} />
                <Skeleton height={15} width={"100%"} />
              </div>
            </div>
            <div className='second-notes-skeleton'>
              <Skeleton height={18} width={"100%"} />
            </div>
          </div>
          <br />
          <div className='notes-skeleton'>
            <div className="first-notes-skeleton">
              <div>
                <Skeleton height={18} width={"100%"} />
                <Skeleton height={15} width={"90%"} />
              </div>
              <div>
                <Skeleton height={15} width={"100%"} />
                <Skeleton height={13} width={"90%"} />
                <Skeleton height={15} width={"100%"} />
              </div>
            </div>
            <div className='second-notes-skeleton'>
              <Skeleton height={18} width={"100%"} />
            </div>
          </div>
          <br />
          <div className='notes-skeleton'>
            <div className="first-notes-skeleton">
              <div>
                <Skeleton height={18} width={"100%"} />
                <Skeleton height={15} width={"90%"} />
              </div>
              <div>
                <Skeleton height={15} width={"100%"} />
                <Skeleton height={13} width={"90%"} />
                <Skeleton height={15} width={"100%"} />
              </div>
            </div>
            <div className='second-notes-skeleton'>
              <Skeleton height={18} width={"100%"} />
            </div>
          </div>
        </>
      ) : (
        <>
          {data.length == 0 ?
            <div class="not-found">There are no notes.</div> :
            (<>
              {data.map((item, index) => (
                <Reports
                  key={index}
                  name={item.name}
                  url={url}
                  date={item.creation.substring(0, 16)}
                  textDate={'in Force Date'}
                  title={item.document_name}
                  textTitle={item.document_type}
                  showNote={true}
                  notePara={item.details}
                  onDeleteNote={handleNoteDelete} // Pass the callback function
                  docName={item.document_name}
                  titleLink={`/dashboard/regulation/${encodeURIComponent(item.document_name)}`} 
                />
              ))}
              <div className='mt-5'>
                <Paginations currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
              </div>
            </>
            )
          }
        </>
      )}
    </div>
  );
};

export default Results;