import React, { useState, useEffect } from 'react'
import "./Notes.css"
import { Title, DatePickers, Text, UnderDevelopment } from "../../Components"
import titleImg from "../../assets/images/svg/notes.svg"
import { Results } from "../../Features/Notes"
import Modal from 'react-modal';

const Notes = ({ url , dashboardData, selectedFilters, setSelectedFilters,searchQuery,setSearchQuery,callChangeReportFilters, reportObject,loading }) => {
    const notesSection = dashboardData?.find((section) => section.section === "Notes List");
    const [isClear, setIsClear] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedDate, setSelectedDate] = useState({ startDate: null, endDate: null });

    const togglePopup = () => {
        setShowPopup(!showPopup);
    }

    const handleDateChange = (date) => {
  
      if (!date || !date.startDate || !date.endDate) {
        // Handle the case where date or startDate or endDate is null
        return;
      }
  
      // Function to format date as "Y-%m-%d %H:%M:%S"
      const formatDate = (inputDate) => {
        if (!inputDate) return null;
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const day = inputDate.getDate().toString().padStart(2, '0');
        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');
        const seconds = inputDate.getSeconds().toString().padStart(2, '0');
  
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };
  
      // Set time to midnight for the start date and format it
      const startDateTime = date.startDate ? formatDate(new Date(date.startDate.setHours(0, 0, 0, 0))) : null;
  
      // Set time to midnight for the end date and format it 23, 59, 59
      const endDateTime = date.endDate ? formatDate(new Date(date.endDate.setHours(23, 59, 59, 0))) : null;
  
      setSelectedDate({
        startDate: startDateTime,
        endDate: endDateTime
      });
    };

    const handleResetDate = () => {
        setSelectedDate(null); // Reset the selected date
        setIsClear(true);
    };

    useEffect(() => {
        if ( (selectedDate && (selectedDate.startDate !== null || selectedDate.endDate !== null))){
          setIsClear(false);
        }
      }, [selectedDate]);

    return (
        <div className='innerpages-container'>
            <Title 
                title={"Notes List"}  
                showInfo={false} 
                icon={titleImg} 
                titleBtn={true}
                onDateChange={handleDateChange}
                onReset={handleResetDate}
                url={url}
                dashboardData={dashboardData}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                reportObject={reportObject}
                callChangeReportFilters={callChangeReportFilters}
                loading={loading}
            />
            <p className='innerpages-para'>
            {notesSection?.data?.page_description ??
               " Provide information about changes ta leg slation that the government has announced or passed. it covers the previous 60 days, but you can change tne date range to cover longer or shorter periods. You can also filter to Amendments to focus on specific jurisdictions and upcoming va in force amendments"
            }
            </p>
            <div className='amendments-info'>
                <div className='hide-responsive'>
                    <Text para={"Filter by data range:"} />
                    <div className="mt-2">
                    <DatePickers
                    inputType={"datetime"}
                    onDateChange={handleDateChange}
                    onReset={handleResetDate}
                    />
                </div>
                </div>
                {/* <div className='hide-responsive'>
                    <Text para={"Filter by jurisdictions"} />
                    <div className='mt-2' onClick={togglePopup}>
                        <SelectFilter />
                    </div>
                </div> */}
            </div>
            <Results
                url={url}
                selectedFilters={selectedFilters}
                dashboardData={dashboardData}
                clearedDate={isClear}
                selectedDate={selectedDate}
                searchQuery= {searchQuery}
            />
            <Modal
                isOpen={showPopup}
                onRequestClose={togglePopup}
                contentLabel="Select Filter Popup"
            >
                <UnderDevelopment btnClick={togglePopup} />
            </Modal>
        </div>
    )
}

export default Notes
