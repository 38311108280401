import React, { useState,forwardRef } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import './DateTimePickers.css'; 
import dayjs from 'dayjs';

const DateTimePickerValue = forwardRef(({ handleDateTimeChange, value }, ref) => {
  return (
    <div ref={ref}>
      <div className="date-time-picker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateTimePicker"]}>
            <DateTimePicker
              value={value}
              onChange={handleDateTimeChange}
              PopperProps={{
                id: 'date-time-picker-popper', // Add a unique ID to the popper
                disablePortal: false, // Ensure it can be detected in the DOM
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </div>
  );
});

export default DateTimePickerValue;