import React from 'react'
import "./LinkBtn.css"
import { Link } from 'react-router-dom'

const LinkBtn = ({ title, bg, color,to }) => {
    return (
        <a href={to} data-aos="zoom-out" data-aos-duration="1500" className='link-btn' style={{ background: `${bg}`, color: `${color}` }}>
            {title}
        </a>
    )
}

export default LinkBtn
