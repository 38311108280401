import React, { useEffect, useState } from 'react';
import './MainNavbar.css';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../Assets/images/svg/logo.svg';
import logoScrolled from "../../Assets/images/svg/logo-dark.svg"
import Cookies from "js-cookie";
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';

const MainNavbar = ({ url }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const userImage = Cookies.get("user_image");
  const userName = Cookies.get("username");
  const fullName = Cookies.get("full_name")
  const userNameBeforeAt = userName ? userName.split('@')[0] : '';
  const userNameFirstChar = userName ? userName[0] : '';
  const [isLoggedIn, setIsLoggedIn] = useState(false); // User authentication state

  const clearCookies = () => {
    const cookies = document.cookie.split(";");
  
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
  };
  const handleLogout = async () => {
    try {
      const response = await fetch(`${url}/api/method/logout`, {
        method: 'GET', 
        credentials: 'include', 
      });
      await response.json();
      window.location.href = '/'; 
    } catch (error) {
      console.error('Error during logout:', error);
    }
    clearCookies(); 

  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userName) {
      setIsLoggedIn(true);
    } else  {
      setIsLoggedIn(false);
    } 
      
  } );

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100; // Threshold in pixels

      if (scrollPosition > scrollThreshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isActiveLink = (href) => {
    return location.pathname === href;
  };

  const logoSrc = isActiveLink('/') ||
   isActiveLink('/home') ||
    isActiveLink('/pricing') ||
     isActiveLink('/payment') || 
     isActiveLink("/login") || 
     isActiveLink("/register") || 
     isActiveLink("/checkemail") || 
     isActiveLink("/emailverified") || 
     isActiveLink("/resetpassword/:email/:code") ? (isScrolled ? logoScrolled : logo) : logoScrolled;

  return (
    <Navbar
      expand="lg"
      className={`website-nav ${isScrolled ? "website-nav-scrolled" : ""}`}
    >
      <Container
        className={
          isActiveLink("/") ||
          isActiveLink("/home") ||
          isActiveLink("/pricing") ||
          isActiveLink("/payment") || 
          isActiveLink("/login") || 
          isActiveLink("/register") || 
          isActiveLink("/checkemail") || 
          isActiveLink("/emailverified") || 
          isActiveLink("/resetpassword/:email/:code") 
            ? "website-navbar-container"
            : "inner-page-nav"
        }
      >
        <Navbar.Brand href="/home">
          <img className="nav-logo" src={logoSrc} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto">
            <Nav.Link
              // as={Link}
              href="/home"
              className={
                isActiveLink("/") || isActiveLink("/home")
                  ? "active-website-nav"
                  : ""
              }
            >
              Home
            </Nav.Link>
            {/* <NavDropdown title="About" id="about-dropdown">
              <NavDropdown.Item as={Link} to="/about">
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ourteam">
                Our Team
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link
              // as={Link}
              href="/about"
              className={isActiveLink("/about") ? "active-website-nav" : ""}
            >
              About Us
            </Nav.Link>
            <Nav.Link
              // as={Link}
              href="/pricing"
              className={isActiveLink("/pricing") ? "active-website-nav" : ""}
            >
              Pricing
            </Nav.Link>
            <Nav.Link
              // as={Link}
              href="/ourteam"
              className={isActiveLink("/ourteam") ? "active-website-nav" : ""}
            >
              Our Team
            </Nav.Link>
          </Nav>
          <div className="navbar-btns">
            <div >
              {!isLoggedIn ? (
                <div className="navbar-btns">
                  <a className="navbar-login" href="/login">
                    Login
                  </a>
                  <a href="/bookdemo">
                  <button className="navbar-book">Book Demo</button>
                  </a>

                </div>
              ): (
              
              <div>
              <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar 
                      sx={{ width: 32, height: 32 }} 
                      src={userImage ? url + userImage : undefined} 
                      alt={userNameFirstChar}
                    >
                      {!userImage && userNameFirstChar}
                    </Avatar>
                </IconButton>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  className="profile-name"
                >
                  {fullName} <ArrowDropDownOutlinedIcon fontSize="small" />
                </Button>
              </Tooltip>
            </Box>
            <Menu
              disableScrollLock={true}
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

              <a className="profile-dropdown-link" href="/dashboard">
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <DashboardIcon fontSize="small" />
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
              </a>

              <MenuItem onClick={() => { handleClose(); handleLogout(); }}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </React.Fragment>
              </div>
              )
              }
       
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;