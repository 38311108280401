import React, { useState, useRef, useEffect } from "react";
import "./AmendmentsNote.css";
import {
  Text,
  MenuDots,
  AddBtn,
  ReminderPopUp,
  NotePopUp,
} from "../../Components";
import addBtn from "../../assets/images/svg/addbtn.svg";
import { Row, Col } from "react-bootstrap";
import { AddReport } from "../../Components";
import Modal from "react-modal";

const AmendmentsNote = ({
  url,
  docName,
  title,
  textTitle,
  noteCount,
  reminderCount,
  reportCount,
  date,
  textDate,
  titleLink,
  onRefresh,
}) => {
  const [showAddNote, setShowAddNote] = useState(false);
  const [showAddReminder, setShowAddReminder] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const notePopUpRef = useRef(null);
  const reminderPopUpRef = useRef(null);
  const dateTimePickerRef = useRef(null);

  const btnContainerRef = useRef(null); // Ref for the container of the buttons

  const handleAddNoteClick = () => {
    setShowAddNote(true);
    setShowAddReminder(false);
  };

  const handleAddReminderClick = () => {
    setShowAddReminder(true);
    setShowAddNote(false);
  };

  const closePopups = () => {
    setShowAddNote(false);
    setShowAddReminder(false);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // const handleClickOutside = (event) => {
  //     // Check if the click is outside the popups and the buttons container
  //     if (
  //         notePopUpRef.current && !notePopUpRef.current.contains(event.target) &&
  //         reminderPopUpRef.current && !reminderPopUpRef.current.contains(event.target) &&
  //         btnContainerRef.current && !btnContainerRef.current.contains(event.target)
  //     ) {
  //         closePopups();
  //     }
  // };

  const handleClickOutside = (event) => {
    if (notePopUpRef.current && !notePopUpRef.current.contains(event.target)) {
      setShowAddNote(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutsideReminder = (event) => {
    const isClickInsideReminderPopup =
      reminderPopUpRef.current &&
      reminderPopUpRef.current.contains(event.target);
    const isClickInsideDatePicker =
      dateTimePickerRef.current &&
      dateTimePickerRef.current.contains(event.target);
    const isClickInsideDatePickerPopper = document
      .querySelector('[role="dialog"]')
      ?.contains(event.target);

    if (
      !isClickInsideReminderPopup &&
      !isClickInsideDatePicker &&
      !isClickInsideDatePickerPopper
    ) {
      setShowAddReminder(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutsideReminder);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideReminder);
    };
  }, []);

  const parentOptions = [
    <div onClick={togglePopup}>
      <AddBtn
        key="addToReport"
        name={"Add to Report"}
        // count={reportCount}
        img={addBtn}
      />
    </div>,
    <div onClick={handleAddNoteClick}>
      <AddBtn key="addNote" name={"Add Note"} count={noteCount} img={addBtn} />
    </div>,
    <div onClick={handleAddReminderClick}>
      <AddBtn
        key="addReminder"
        name={"Add Reminder"}
        count={reminderCount}
        img={addBtn}
      />
    </div>,
  ];

  return (
    <Row className="note-content">
      <Col md={3} lg={4}>
        <animate>
          <a href={`${titleLink}`}>
            <div className="note-info">
              <h1 className="note-date">{date}</h1>
              <Text para={textDate} />
            </div>
          </a>
        </animate>
      </Col>
      <Col md={7} lg={8} className="regulation-note-content">
        <div>
          <a href={`${titleLink}`}>
            <h1 className="note-title">{title}</h1>
          </a>
          <Text para={textTitle} />
        </div>
        <div ref={btnContainerRef} className="menudots-sm">
          {" "}
          {/* Wrap buttons with the ref */}
          <MenuDots options={parentOptions} />
        </div>

        <Modal isOpen={showPopup} onRequestClose={togglePopup}>
          <AddReport cancelBtn={togglePopup} url={url} regulation={docName} />
        </Modal>

        <div
          ref={notePopUpRef}
          className={`NotePopUp ${showAddNote ? "active" : ""}`}
        >
          <NotePopUp
            count={noteCount.toString()}
            isOpen={showAddNote}
            onCancel={closePopups}
            url={url}
            docName={docName}
            onRefresh={onRefresh}
          />
        </div>

        <div
          ref={reminderPopUpRef}
          className={`ReminderPopUp ${showAddReminder ? "active" : ""}`}
        >
          <ReminderPopUp
            url={url}
            count={reminderCount.toString()}
            isOpen={showAddReminder}
            docName={docName}
            onCancel={closePopups}
            onRefresh={onRefresh}
            dateTimePickerRef={dateTimePickerRef} 
          />
        </div>
      </Col>
    </Row>
  );
};

export default AmendmentsNote;
