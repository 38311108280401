import React, { useState } from 'react';
import axios from 'axios';
import './RegisterForm.css'
import { Container, Row, Col } from 'react-bootstrap';
import PhoneInput from "react-phone-number-input";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';



const RegisterForm=({ url ,data })=>{
    const registerSection = data?.find(item => item.section === "Register Form");

    const { industry_types = [], company_sizes = [] } = (registerSection && registerSection.data && registerSection.data.content) || {};


    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        organization: "",
        interest: "",
        industry: "",
        companySize: "",
        note: "",
        profilePicture: null,
        profilePictureBase64: "",
        profilePictureName: ""
    });

    const [registrationMessage, setRegistrationMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profilePicture' && files.length > 0) {
            const file = files[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                profilePicture: file,
                profilePictureName: file.name
            }));
            convertFileToBase64(file);
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const convertFileToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                profilePictureBase64: reader.result
            }));
        };
    };

    const handlePhoneChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            phoneNumber: value
        }));
    };

    const handleClick = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      const apiUrl = `https://testapi.polarisai.ca/api/method/erpnext.selling.doctype.customer.customer.company`;

      const data = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          company_size: formData.companySize,
          company_name: formData.organization,
          location: formData.note,
          email: formData.email,
          phone_number: formData.phoneNumber,
          logo: {
              filename: formData.profilePictureName,
              data: formData.profilePictureBase64
          }
      };

      try {
          const apiKey = Cookies.get('api_key');
          const apiSecret = Cookies.get('api_secret');
          const response = await axios.post(apiUrl, data, {
              headers: {
                  'Content-Type': 'application/json'
              },
          });

          if (response.data && response.data.status_code === 200) {
              setRegistrationMessage(response.data.message);
              navigate('/checkemail', { state: { email: formData.email } });
          } else {
               setRegistrationMessage(response.data.message);
              // setRegistrationMessage('Unexpected response structure.');
          }
      } catch (error) {
          setRegistrationMessage(error);
          // setRegistrationMessage('Registration failed. Please try again.');
      } finally {
          setIsSubmitting(false);
      }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.company`;

        const data = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            company_size: formData.companySize,
            company_name: formData.organization,
            location: formData.note,
            email: formData.email,
            phone_number: formData.phoneNumber,
            logo: {
                filename: formData.profilePictureName,
                data: formData.profilePictureBase64
            }
        };
        try {
            // const apiKey = Cookies.get('api_key');
            // const apiSecret = Cookies.get('api_secret');
            const response = await axios.post(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.data && response.data.status_code === 200) {
                setRegistrationMessage(response.data.message);
                navigate('/checkemail', { state: { email: formData.email } });
            } else {
              console.log(response.data.message)
                // console.error('Unexpected response structure:', response.data);
                // setRegistrationMessage('Unexpected response structure.');
                setRegistrationMessage(response.data.message);
            }
        } catch (error) {
            // console.error('Registration failed', error);
            setRegistrationMessage('Registration failed. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };
  return(
    <div >
     <form className="book-demo-form" onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <div className="contact-input">
                  <p className="contact-input-title">
                    First Name <span>*</span>
                  </p>
                  <input
                    type="text"
                    className="contact-input-text"
                    placeholder="first name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="contact-input">
                  <p className="contact-input-title">
                    Last Name <span>*</span>
                  </p>
                  <input
                    type="text"
                    className="contact-input-text"
                    placeholder="last name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="contact-input">
                  <p className="contact-input-title">
                    Phone number <span>*</span>
                  </p>
                  <PhoneInput
                    defaultCountry={"CA"}
                    international={true}
                    className="contact-input-text"
                    value={formData.phoneNumber}
                    onChange={handlePhoneChange}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="contact-input">
                  <p className="contact-input-title">
                    Email Address<span>*</span>
                  </p>
                  <input
                    type="text"
                    className="contact-input-text"
                    placeholder="yourName@email.com"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <div className="contact-input">
                <p className="contact-input-title">Profile Picture</p>
                <input
                  type="file"
                  className="contact-input-text"
                  name="profilePicture"
                  onChange={handleInputChange}
                />
              </div>
              <div className="contact-input">
                <p className="contact-input-title">Organization</p>
                <input
                  type="text"
                  className="contact-input-text"
                  placeholder="Organization"
                  name="organization"
                  value={formData.organization}
                  onChange={handleInputChange}
                />
              </div>
              {/* <div className="contact-input">
                <p className="contact-input-title">I’m interested in</p>
                <select
                  className="contact-input-text"
                  name="interest"
                  value={formData.interest}
                  onChange={handleInputChange}
                >
                  <option value="first">Select one</option>
                </select>
              </div> */}
              <div className="contact-input">
                <p className="contact-input-title">Industry</p>
                <select
                  className="contact-input-text"
                  name="industry"
                  value={formData.industry}
                  onChange={handleInputChange}
                >
                  {industry_types.map((industry, index) => (
                    <option key={index} value={industry.name}>
                      {industry.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="contact-input">
                <p className="contact-input-title">Company Size</p>
                <select
                  className="contact-input-text"
                  name="companySize"
                  value={formData.companySize}
                  onChange={handleInputChange}
                >

                  {company_sizes.map((company, index) => (
                    <option key={index} value={company.name}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="contact-input">
                <p className="contact-input-title">Note</p>
                <textarea
                  rows={"5"}
                  className="contact-input-text"
                  name="note"
                  value={formData.note}
                  onChange={handleInputChange}
                ></textarea>
              </div>
              <div className="contact-input">
                <input
                  type="submit"
                  value="BOOK DEMO"
                  className="contact-submit"
                  disabled={isSubmitting}
                />
              </div>
              <div className="contact-input d-none">
                <input
                  type="button"
                  onClick={handleClick}
                  value="BOOK DEMO"
                  className="contact-submit"
                  disabled={isSubmitting}
                />
              </div>
            </Row>
          </form>
          {registrationMessage && <p>{registrationMessage}</p>}

    </div>
  )
}
export default RegisterForm;