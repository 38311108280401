import React from 'react'
import "./Updates.css"
import { Title, Text, DatePickers, ChartComponent } from '../../../../Components'

const Updates = ({ dashboardData }) => {
    return (
        <div className='updates'>
            <Title
                title={"Recent Amendments"}
                showInfo={true}
                titleBtn={false}
                tooltip={`"Recent Amendments" provides information about the number of adjustments made each year using the chart.`}
            />
            <div className='mt-2'>
                <p className='innerpages-para'>
                    This bar chart visualizes the number of amendments made each year, highlighting the year with the highest number of amendments and helping to identify trends and significant changes in activity.
                </p>
            </div>

            <div className='chart-container'>
                <ChartComponent dashboardData={dashboardData} />
            </div>
        </div>
    )
}

export default Updates
