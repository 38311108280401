import React, { useState, useEffect } from 'react'
import "./ReportsDetails.css"
import { Title, DatePickers, Text } from "../../Components"
import { Results } from "../../Features/ReportsDetails"
import titleImg from "../../assets/images/svg/reports.svg"
import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";


const ReportsDetails = ({ url, dashboardData, selectedFilters,setSelectedFilters, searchQuery,setSearchQuery,callChangeReportFilters, reportObject,setCallChangeReportFilters, setReportObject }) => {
    const reportSection = dashboardData?.find(item => item.section === "Reports List");
    const [reportData, setReportData] = useState({});
    const reportName = window.location.pathname.split('/').filter(Boolean).pop();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, [reportName]);


    // useEffect(() => {
    //     localStorage.setItem('returnedFrom', "ReportDetails");
    // }, []);

    


    useEffect(() => {
        if (reportData && reportData.filters) {
            const updatedFilters = {};
            
            reportData.filters.forEach(filter => {
                if (filter.field === 'area_of_laws' || filter.field === 'topics' || filter.field === 'jurisdictions') {
                    if (!updatedFilters[filter.field]) {
                        updatedFilters[filter.field] = [];
                    }
                    // Split the filter.value by comma
                    const values = filter.value.split(',');
                    values.forEach(value => {
                        if (updatedFilters[filter.field].includes(value.trim())) {
                            updatedFilters[filter.field] = updatedFilters[filter.field].filter(
                                existingValue => existingValue !== value.trim()
                            );
                        } else {
                            updatedFilters[filter.field].push(value.trim());
                        }
                    });
                }
            });
            setSelectedFilters(updatedFilters);
        }
        setReportObject({
            "name": reportData.name,
            "report_doctype": reportData.report_doctype,
            "report_name": reportData.report_name,
            "topic": reportData.topic,
            "note": reportData.note,
            "query": {
                "search": null,
                "jurisdiction": "",
                "area_of_laws": "",
                "topics": "",
            },
            "execute": 1
        })
        setCallChangeReportFilters(() => fetchData)

    }, [reportData])

    const fetchData = async () => {
        setLoading(true);
        const apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report?name=${reportName}`;
        try {
            const apiKey = Cookies.get('api_key');
            const apiSecret = Cookies.get('api_secret');
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `token ${apiKey}:${apiSecret}`,
                },
            });
            if (response.data && response.data.status_code === 200) {
                setReportData(response.data.result);
            } else {
            }
        } catch (error) {
            toast.error("Failed to fetch data. Please try again..");
        }
        setLoading(false);
    };

    return (
        <div className='innerpages-container' style={{position:"relative"}}>
            <Title
                title={reportData.report_name}
                showInfo={false}
                icon={titleImg}
                titleBtn={true}
                url={url}
                dashboardData={dashboardData}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                reportObject={reportObject}
                callChangeReportFilters={callChangeReportFilters}
            />
            <p className='innerpages-para'>
                {reportSection?.data?.page_description ?? ""}
            </p>
            <div className='amendments-info'>
                <div className='hide-date-responsive'>
                    <Text para={"Filter by data range:"} />
                    <div className='mt-2'>
                        <DatePickers inputType={"datetime"} />
                    </div>
                </div>
            </div>
            <Results
                url={url} reportID={reportData.name} reportName={reportData.report_name}
                reportNote={reportData.note} reportTopic={reportData.topic}
                regulations={reportData.regulations} filters={reportData.filters} onRefresh={fetchData} loading={loading}
                setLoading={setLoading} searchQuery={searchQuery}
            />
        </div>
    )
}

export default ReportsDetails
