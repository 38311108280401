import React, { useState, useEffect } from "react";
import "./Search.css";
import { DatePickers, Text, Title, SearchInput } from "../../Components";
import { Results } from "../../Features/Search";

const Search = ({ url, dashboardData, selectedFilters, setSelectedFilters,searchQuery,setSearchQuery,callChangeReportFilters, reportObject,loading }) => {
  const [searchText, setSearchText] = useState("");
  const [isClear, setIsClear] = useState(false);

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleResetDate = () => {
    setSelectedDate(null); // Reset the selected date
    setIsClear(true);
  };

  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });

  const handleDateChange = (date) => {

    if (!date || !date.startDate || !date.endDate) {
      // Handle the case where date or startDate or endDate is null
      return;
    }

    // Function to format date as "Y-%m-%d %H:%M:%S"
    const formatDate = (inputDate) => {
      if (!inputDate) return null;
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
      const day = inputDate.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    // Set time to midnight for the start date and format it
    const startDateTime = date.startDate
      ? formatDate(new Date(date.startDate))
      : null;

    // Set time to midnight for the end date and format it
    const endDateTime = date.endDate
      ? formatDate(new Date(date.endDate))
      : null;

    setSelectedDate({
      startDate: startDateTime,
      endDate: endDateTime,
    });
  };

  useEffect(() => {}, [selectedDate]);

  return (
    <div className="search">
      <Title
        title="Search Results"
        showInfo={false}
        titleBtn={true}
        onDateChange={handleDateChange}
        onReset={handleResetDate}
        url={url}
        dashboardData={dashboardData}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        reportObject={reportObject}
        callChangeReportFilters={callChangeReportFilters}
        loading={loading}
      />
      <SearchInput
        searchText={searchText}
        setSearchText={setSearchText}
        handleClearSearch={handleClearSearch}
      />
      <div className="hide-date-responsive">
        <Text para="Filter by date range:" />
        <div className="mt-2 hide-responsive">
          <DatePickers
            inputType={"datetime"}
            onDateChange={handleDateChange}
            onReset={handleResetDate}
          />
        </div>
      </div>

      <Results
        url={url}
        searchText={searchText}
        selectedDate={selectedDate}
        dashboardData={dashboardData}
        selectedFilters={selectedFilters}
        clearedDate={isClear}
      />
    </div>
  );
};

export default Search;
