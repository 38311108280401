import React from 'react'
import "./OurTeam.css"
import { Hero } from '../../Components'
import { AboutTeam } from '../../Features/OurTeam'

const OurTeam = ({url, data}) => {

  const teamSection = data?.find(item => item.section === "Team Section");
  const contactSection = data?.find(item => item.section === "Contact Us Section");

  return (
    <div>
        <Hero
            mainTitle={"Home /"}
            title={"About us / Our team"}
            para={"Our team"}
        />
        <AboutTeam  url={url} teamSection={teamSection} contactSection={contactSection}  />
    </div>
  )
}

export default OurTeam