import React, { useEffect, useState } from 'react';
import './Results.css';
import axios from 'axios';
import { Paginations, Debounce } from '../../../../Components';
import { Reports } from '../../../../Features/Reminders';
import Cookies from 'js-cookie';
import Skeleton from 'react-loading-skeleton';
import { toast } from "react-toastify";

const Results = ({ url, selectedDate, selectedFilters, dashboardData, clearedDate, searchQuery }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const resultsPerPage = 20;
  const debouncedSearchQuery = Debounce(searchQuery, 500); // 500ms debounce delay

  const processAndSortData = (data) => {
    const currentDate = new Date(); // Get current date   data.content.reminders.objects
    const modifiedData = data?.map((item) => {
      const remindAtDate = new Date(item.remind_at); // Convert remind_at string to date object
      const timeDifference = remindAtDate.getTime() - currentDate.getTime(); // Calculate the time difference in milliseconds
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Convert milliseconds to hours

      return {
        ...item,
        days,
        hours,
      };
    });

    const sortedData = modifiedData.sort((a, b) => {
      // Sort in ascending order by the time residual (days and hours)
      const timeResidualA = a.days * 24 + a.hours;
      const timeResidualB = b.days * 24 + b.hours;
      return timeResidualA - timeResidualB;
    });

    return sortedData || [];
  };

  useEffect(() => {
    // Extract the initial data from dashboardData
    const reminderSection = dashboardData?.find(
      (section) => section.section === "Reminders List"
    );
    if (reminderSection) {
      const initialData = reminderSection.data.content.reminders.objects;
      const pagination = reminderSection.data.content.reminders.pagination.metadata;
      const sortedData = processAndSortData(initialData); // Process and sort the data
      setData(sortedData); // Set the sorted data in the state
      setCurrentPage(pagination.current_page);
      setTotalPages(pagination.total_pages);
      setIsLoading(false);
    }
  }, [dashboardData]);

  useEffect(() => {
    if ((selectedFilters != {} && selectedFilters != undefined && Object.keys(selectedFilters).length > 0) || (selectedDate && (selectedDate.startDate !== null || selectedDate.endDate !== null)) || (clearedDate && clearedDate === true)) {
      fetchData();
    }
  }, [selectedFilters, selectedDate, clearedDate]);


  useEffect(() => {
    if (debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (searchQuery === "" && debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [searchQuery]);


  const fetchData = async (pageNumber = 1) => {

    try {
      // setIsLoading(true);
      const apiKey = Cookies.get('api_key');
      const apiSecret = Cookies.get('api_secret');
      const filterParams = Object.keys(selectedFilters)
        .filter((type) => selectedFilters[type].length > 0)
        .map((type) => {
          if (Array.isArray(selectedFilters[type])) {
            return `${type}=${encodeURIComponent(
              selectedFilters[type].join(",")
            )}`;
          } else {
            return `${type}=${encodeURIComponent(selectedFilters[type])}`;
          }
        })
        .join("&");
      const offset = (pageNumber - 1) * resultsPerPage;

      // Construct the URL with or without selectedDate query
      let apiUrl = `${url}/api/method/frappe.automation.doctype.reminder.reminder.reminder?start=${offset}&limit=${resultsPerPage}${filterParams ? `&${filterParams}` : ""}${searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ''}`;
      if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
        apiUrl += `&remind_at_start=${selectedDate.startDate}&remind_at_end=${selectedDate.endDate}`;
      }

      setIsLoading(true); // Start loading
      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });
      if (response.data.status_code !== 200) {
        toast.error("Failed to fetch data. Please try again..");
      }

      const result = response.data.result.objects;

      const sortedData = processAndSortData(result); // Process and sort the data
      setData(sortedData); // Set the sorted data in the state
      setCurrentPage(response.data.result.pagination.metadata.current_page || 1);
      setTotalPages(response.data.result.pagination.metadata.total_pages || 1);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to fetch data. Please try again..");

      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const onPageChange = (pageNumber) => {
    fetchData(pageNumber);
  };
  const handleReminderDelete = () => {
    fetchData(currentPage);
  };

  return (
    <div className="results">
      {isLoading ? (
        <>
          <div className='reminders-skeleton'>
            <div className='first-rminders-skeleton'>
              <div>
                <Skeleton height={18} width={"100%"} />
                <Skeleton height={15} width={"88%"} />
              </div>
              <div>
                <Skeleton height={15} width={"100%"} />
                <Skeleton height={13} width={"85%"} />
                <Skeleton height={15} width={"100%"} />
              </div>
            </div>
            <div className='second-rminders-skeleton'>
              <Skeleton height={18} width={"100%"} />
            </div>
          </div>
          <br />
          <div className='reminders-skeleton'>
            <div className='first-rminders-skeleton'>
              <div>
                <Skeleton height={18} width={"100%"} />
                <Skeleton height={15} width={"88%"} />
              </div>
              <div>
                <Skeleton height={15} width={"100%"} />
                <Skeleton height={13} width={"85%"} />
                <Skeleton height={15} width={"100%"} />
              </div>
            </div>
            <div className='second-rminders-skeleton'>
              <Skeleton height={18} width={"100%"} />
            </div>
          </div>
          <br />
          <div className='reminders-skeleton'>
            <div className='first-rminders-skeleton'>
              <div>
                <Skeleton height={18} width={"100%"} />
                <Skeleton height={15} width={"88%"} />
              </div>
              <div>
                <Skeleton height={15} width={"100%"} />
                <Skeleton height={13} width={"85%"} />
                <Skeleton height={15} width={"100%"} />
              </div>
            </div>
            <div className='second-rminders-skeleton'>
              <Skeleton height={18} width={"100%"} />
            </div>
          </div>
        </>
      ) : (
        <>

          {!data || data.length == 0 ? (<div class="not-found">There are no reminders.</div>)

            : (<>
              {data.map((item, index) => (
                <Reports
                  name={item.name}
                  url={url}
                  key={index}
                  date={item.remind_at}
                  textDate={item.textDate}
                  title={item.reminder_doctype}
                  textTitle={item.description}
                  title2={item.reminder_docname}
                  para={item.para}
                  disable={item.disable}
                  showButton={new Date(item.remind_at) >= new Date()} // Enable the button only if the date is in the future
                  removeBtn={new Date(item.remind_at) >= new Date() ? (item.disable === 1 ? "Activate Reminder" : "Deactivate Reminder") : "Expired"}
                  days={item.days === 0 ? null : item.days}
                  hours={item.hours}
                  onDeleteReminder={handleReminderDelete} // Pass the callback function
                  titleLink={`/dashboard/regulation/${encodeURIComponent(item.reminder_docname)}`}
                />
              ))}
              <div className='mt-5'>
                <Paginations
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              </div>
            </>
            )
          }

        </>
      )}
    </div>
  );
};

export default Results;