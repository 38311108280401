import React from 'react'
import "./Hr.css"

const Hr = ({ image, title }) => {
    return (
        <div className='hr-component-container' data-aos="zoom-in" data-aos-duration="1500">
            <div className='hr-component-image'>
                <img src={image} />
            </div>
            <h1 className='hr-component-title'>{title}</h1>
        </div>
    )
}

export default Hr
