import React, { useEffect, useState } from 'react';
import './Results.css';
import axios from 'axios';
import { Reports } from "../../index"
import Skeleton from 'react-loading-skeleton';
import Cookies from 'js-cookie';
import { Paginations, } from "../../../../Components";
import { toast } from "react-toastify";


// Debounce function
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);


  // useEffect(() => {
  //   // Update debouncedValue only when value changes
  //   setDebouncedValue(value);
  // }, [value]);

  return debouncedValue;
};

const Results = ({ url, reportID, reportName, reportNote, reportTopic, regulations, filters, onRefresh, loading, searchQuery, setLoading }) => {

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const debouncedSearchQuery = useDebounce(searchQuery, 500); // 500ms debounce delay
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  let pageNumber = 1;

  const fetchData = async () => {
    try {
      setLoading(true);
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
      let apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report?name=${reportID}&start=${pageNumber}&limit=20${searchQuery ? `&search_reports=${encodeURIComponent(searchQuery)}` : ''}`;
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });
      if (response.data.status_code === 200) {
        setData(response.data.result.regulations?.objects);
        setCurrentPage(response.data.result.regulations?.pagination?.metadata?.current_page);
        setTotalPages(response.data.result.regulations?.pagination?.metadata?.total_pages);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to fetch data. Please try again..");
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setData(regulations?.objects ?? []);
    setCurrentPage(regulations?.pagination?.metadata?.current_page);
    setTotalPages(regulations?.pagination?.metadata?.total_pages);
  }, [regulations]);

  const onPageChange = (pageNumb) => {
    pageNumber = (pageNumb - 1) * 20;
    fetchData();
  };

  useEffect(() => {
    if (debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (searchQuery === "" && debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [searchQuery]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Check initial screen width on component mount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="results">
      {loading ? (
        <>
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
        </>
      ) :
        (
          <div className='results-reports'>
            {data.length == 0 ?
              <div class="not-found">There are no regulations.</div> :
              (<>
                {data.map((item, index) => (
                  <Reports
                    key={item.name}
                    name={item.name}
                    index={index}
                    titleLink={`/dashboard/regulation/${encodeURIComponent(item.name)}`}
                    property={
                      item.areas_of_laws
                        ? item.areas_of_laws
                          .split(",")
                          .map((area) => area.trim())
                          .join(", ")
                        : ""
                    }
                    title={item.title}
                    para={item.long_title}
                    noteCount={item.notes_count}
                    reminderCount={item.reminders_count}
                    url={url}
                    onRefresh={onRefresh}
                    reportID={reportID}
                    reportName={reportName}
                    reportFilters={filters}
                    reportNote={reportNote}
                    reportTopic={reportTopic}
                    isSmallScreen={isSmallScreen}
                  />
                ))}
                <div className="d-flex justify-content-center mt-5">
                  <Paginations
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
              </>
              )
            }
          </div>
        )}
    </div>
  );
};

export default Results;