import React, { useState, useEffect } from 'react';
import "./ReportsPage.css"
import { Title, DatePickers, Text } from "../../Components"
import titleImg from "../../assets/images/svg/reports.svg"
import { Results } from '../../Features/ReportsPage'

const ReportsPage = ({ url, dashboardData, selectedFilters,setSelectedFilters, searchQuery ,setSearchQuery,callChangeReportFilters, reportObject}) => {

    const reportSection = dashboardData?.find(item => item.section === "Reports List");
    const [isClear, setIsClear] = useState(false);

    const handleResetDate = () => {
        setSelectedDate(null); // Reset the selected date
        setIsClear(true);
      };
    
      const [selectedDate, setSelectedDate] = useState({ startDate: null, endDate: null });
    
      const handleDateChange = (date) => {
    
          if (!date || !date.startDate || !date.endDate) {
              // Handle the case where date or startDate or endDate is null
              return;
       }
        
          // Function to format date as "Y-%m-%d %H:%M:%S"
          const formatDate = (inputDate) => {
            if (!inputDate) return null;
            const year = inputDate.getFullYear();
            const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
            const day = inputDate.getDate().toString().padStart(2, '0');
        
            return `${year}-${month}-${day}`;
          };
        
          // Set time to midnight for the start date and format it
          const startDateTime = date.startDate ? formatDate(new Date(date.startDate)) : null;
        
          // Set time to midnight for the end date and format it
          const endDateTime = date.endDate ? formatDate(new Date(date.endDate)) : null;
        
          setSelectedDate({
            startDate: startDateTime,
            endDate: endDateTime
          });
        };
      
      useEffect(() => {
        }, [selectedDate]);

    return (
        <div className='innerpages-container'>
            <Title
                title={"Reports"}
                showInfo={false}
                icon={titleImg}
                titleBtn={true}
                onDateChange={handleDateChange}
                onReset={handleResetDate}
                url={url}
                dashboardData={dashboardData}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                reportObject={reportObject}
                callChangeReportFilters={callChangeReportFilters}
            />
            <p className='innerpages-para'>
                {reportSection?.data?.page_description ?? ""}
            </p>
            <div className='amendments-info'>
                <div className='hide-date-responsive'>
                    <Text para={"Filter by data range:"} />
                    <div className='mt-2'>
                        <DatePickers 
                        inputType={"datetime"} 
                        onDateChange={handleDateChange}
                        onReset={handleResetDate}
                        />
                    </div>
                </div>
            </div>
            <Results
                url={url} dashboardData={dashboardData} selectedFilters={selectedFilters}
                searchQuery={searchQuery}
                selectedDate={selectedDate}
                clearedDate={isClear}
            />
        </div>
    )
}

export default ReportsPage
