import React from 'react'
import "./EmailVerified.css"
import { Container } from 'react-bootstrap'
import trueCheck from "../../assets/images/svg/trueCheck.svg"

const EmailVerified = () => {
    return (
        <div className='auth'>
            <div className='check-email-container'>
                <Container>
                    <div className='email-verified-image'>
                        <img src={trueCheck} />
                    </div>
                    <h1 className='check-email-title'>Email verified</h1>
                    <p className='check-email-para'>Please reset your password</p>
                    <input type='password' className='login-field password-field' placeholder='Password' />
                    <input type='password' className='login-field password-field' placeholder='Confirm Password' />
                    <input type='submit' value="Continue" className='login-submit' />
                </Container>
            </div>
        </div>
    )
}

export default EmailVerified
