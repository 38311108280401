import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./CheckEmail.css";
import { Container } from 'react-bootstrap';
import message from "../../assets/images/svg/message.svg";

const CheckEmail = () => {
  const location = useLocation();
  const email = location.state && location.state.email;
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const inputRefs = useRef([]);

  const handleVerifyEmail = () => {
    if (verificationCode.some(code => code === '')) {
      setError('Please enter the code.');
      return;
    }

    const code = verificationCode.join(''); 
    navigate(`/resetpassword/${email}/${code}`);
  };

  const handleCodeChange = (index, value) => {
    const updatedCode = [...verificationCode];
    updatedCode[index] = value;
    setVerificationCode(updatedCode);

    if (value !== '' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <div className='auth'>
      <div className='check-email-container'>
        <Container>
          <div className='check-email-image'>
            <img src={message} alt='Message' />
          </div>
          <h1 className='check-email-title'>Check your email</h1>
          <p className='check-email-para'>We sent a verification code to</p>
          <p className='check-email-para'>{email}</p>
          <div className='code-digits'>
            {verificationCode.map((digit, index) => (
              <input
                key={index}
                type='text'
                className='code-digit'
                maxLength={1}
                value={digit}
                onChange={(e) => handleCodeChange(index, e.target.value)}
                ref={ref => (inputRefs.current[index] = ref)}
                required
              />
            ))}
          </div>
          {error && <p className='error-message'>{error}</p>}
          <input type='submit' value="Verify Email" className='login-submit' onClick={handleVerifyEmail} />
          <div className='recive-code'>
            <p>Don’t receive the code?</p>
            <a href='#'>Resend the code</a>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CheckEmail;