import React, { useState, useRef, useEffect } from 'react';
import "./RegulationNote.css";
import { Text, MenuDots, AddBtn, ReminderPopUp, NotePopUp } from "../../Components";
import addBtn from "../../assets/images/svg/addbtn.svg";
import { Row, Col } from 'react-bootstrap';
import { AddReport } from "../../Components";
import Modal from "react-modal"

const RegulationNote = ({ url, docName, title, titleLink, textTitle, noteCount, reminderCount, reportCount, date, textDate, onRefresh }) => {

    const [showAddNote, setShowAddNote] = useState(false);
    const [showAddReminder, setShowAddReminder] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const notePopUpRef = useRef(null);
    const reminderPopUpRef = useRef(null);
    const dateTimePickerRef = useRef(null);


    const handleAddNoteClick = () => {
        setShowAddNote(true);
        setShowAddReminder(false);
    };

    const handleAddReminderClick = () => {
        setShowAddReminder(true);
        setShowAddNote(false);
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    }
    const closePopups = () => {
        setShowAddNote(false);
        setShowAddReminder(false);
    };


    const handleClickOutside = (event) => {
        if (notePopUpRef.current && !notePopUpRef.current.contains(event.target)) {
            setShowAddNote(false);
        }
    };


    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutsideReminder = (event) => {
      // Check if the click happened inside the ReminderPopUp
      const isClickInsideReminderPopup = reminderPopUpRef.current && reminderPopUpRef.current.contains(event.target);
  
      // Check if the click happened inside the DateTimePicker input wrapper
      const isClickInsideDatePicker = dateTimePickerRef.current && dateTimePickerRef.current.contains(event.target);
  
      // Check if the click happened inside the DateTimePicker's popper (dropdown)
      const isClickInsideDatePickerPopper = document.querySelector('[role="dialog"]')?.contains(event.target);
  
      // If the click is outside of the ReminderPopUp, DateTimePicker input, and DateTimePicker popper, close the ReminderPopUp
      if (!isClickInsideReminderPopup && !isClickInsideDatePicker && !isClickInsideDatePickerPopper) {
        setShowAddReminder(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener("mousedown", handleClickOutsideReminder);
      return () => {
        window.removeEventListener("mousedown", handleClickOutsideReminder);
      };
    }, []);

    const parentOptions = [
        <div onClick={togglePopup}>
            <AddBtn
                key="addToReport"
                name={"Add to Report"}
                // count={reportCount}
                img={addBtn}
            />
        </div>,
        <div onClick={handleAddNoteClick}>
            <AddBtn
                key="addNote"
                name={"Add Note"}
                count={noteCount}
                img={addBtn}
            />
        </div>
        ,
        <div onClick={handleAddReminderClick}>
            <AddBtn
                key="addReminder"
                name={"Add Reminder"}
                count={reminderCount}
                img={addBtn}
            />
        </div>
    ];

    return (
      <Row className="note-content">
        <Col md={3} lg={4}>
          <a href={`${titleLink}`}>
            <div className="note-info">
              <h1 className="note-date">{date}</h1>
              <Text para={textDate} />
            </div>
          </a>
        </Col>
        <Col md={7} lg={8} className="regulation-note-content">
          <div>
            <a href={`${titleLink}`} className="note-title">
              {title}
            </a>
            <Text para={textTitle} />
          </div>
          <div className="menudots-regulations-sm">
            <MenuDots options={parentOptions} />
          </div>

          <Modal isOpen={showPopup} onRequestClose={togglePopup}>
            <AddReport cancelBtn={togglePopup} url={url} regulation={docName} />
          </Modal>

          <div className={`NotePopUp ${showAddNote ? "active" : ""}`}>
            <NotePopUp
              count={noteCount.toString()}
              isOpen={showAddNote}
              onCancel={closePopups}
              url={url}
              docName={docName}
              onRefresh={onRefresh}
            />
          </div>

          <div ref={reminderPopUpRef} className={`ReminderPopUp ${showAddReminder ? "active" : ""}`}>
            <ReminderPopUp
              url={url}
              count={reminderCount.toString()}
              isOpen={showAddReminder}
              docName={docName}
              onCancel={closePopups}
              onRefresh={onRefresh}
              dateTimePickerRef={dateTimePickerRef} // Pass ref to ReminderPopUp
            />
          </div>
        </Col>
      </Row>
    );
};

export default RegulationNote;