import React, { useState } from 'react';
import axios from 'axios';
import "./ResetPassword.css";
import { Container } from 'react-bootstrap';
import blueLock from "../../assets/images/svg/blueLock.svg";
import { useParams } from 'react-router-dom';

const ResetPassword = ({ url }) => {
  const { email, code } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`${url}/`, {
        key: code,
        new_password: password,
        confirm_password: confirmPassword,
        cmd: 'frappe.core.doctype.user.user.update_password',
        email: email
      });

      // Password reset successful
      // console.log(response.data);
    } catch (error) {
      // Handle error
      setError('Password reset failed. Please try again.');
    }
  };

  return (
    <div className='auth'>
      <div className='check-email-container'>
        <Container>
          <div className='reset-password-image'>
            <img src={blueLock} alt='Lock' />
          </div>
          <h1 className='check-email-title'>Reset Password</h1>
          <p className='check-email-para'>Please reset your password</p>
          <input
            type='password'
            className='login-field password-field'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type='password'
            className='login-field password-field'
            placeholder='Confirm Password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && <p className='error-message'>{error}</p>}
          <input type='submit' value="Continue" className='login-submit' onClick={handleSubmit} />
        </Container>
      </div>
    </div>
  );
};

export default ResetPassword;
