import React from 'react';
import "./AddBtn.css";
import addbtn from "../../assets/images/svg/addbtn.svg";

const AddBtn = ({ name, count, img, svgImage }) => {
    return (
        <div className='add-btn'>
            {
                img ? 
                <img className="add-btn-image" src={img} alt="Add Button" />
                : <div className="add-btn-svg">{svgImage}</div> 
            }
            <h1 className='add-btn-title'>{name}</h1>
            {
                (count !== null && count !== undefined) && <h1 className='add-btn-count'>({count})</h1>
            }
        </div>
    );
}

export default AddBtn;
