import React, { useState } from 'react';
import "./Question.css";
import { Container, Row } from "react-bootstrap";
import QuestionIMG from '../../../../Assets/images/About/pexels-colin-lloyd-2.png'; 
import { AccordionItem } from "../../../index";

const Question = ({url, frequentlySection}) => {

  const { frequently_title, frequently_description, frequently_img, frequently_asked_questions}= frequentlySection.data;

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Container>
      <div className="question-container">
        <Row>
          <div className="col-md-12 col-lg-6">
            <div
              className="question-img-div"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <img className='question-img'
                src={(url+"/"+ frequently_img)}
                alt="QuestionIMG"
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 frequently-questions">
            <div>
              <div
                className="question-title"
                data-aos="fade-down"
                data-aos-duration="1500"
              >
              {frequently_title}
              </div>
              <div
                className="question-text"
                data-aos="fade-down"
                data-aos-duration="1500"
              >
                {frequently_description}
              </div>
            </div>
            <div
              className="accordion"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              {frequently_asked_questions.map((faq, index) => (
                <AccordionItem
                  key={index}
                  index={index}
                  title={faq.question}
                  content={faq.answer}
                  isExpanded={expandedIndex === index}
                  onClick={handleAccordionClick}
                  data-aos="fade-down"
                  data-aos-duration="1500"
                />
              ))}
            </div>
          </div>
        </Row>
      </div>
    </Container>
  );
};

export default Question;