import React from 'react'
import "./NavLink.css"

const NavLink = ({ svgImage, title, isActive }) => {
    const navLinkClasses = isActive ? 'custome-nav-link active-custome-nav-link' : 'custome-nav-link';

    return (
        <div className={navLinkClasses}>
            <div className='custome-nav-link-image'>
                {
                    svgImage
                }
            </div>
            <h1 className='custome-nav-link-title'>{title}</h1>
        </div>
    )
}

export default NavLink
