import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "./Title.css";
import { Tooltip } from "react-tooltip";
import info from "../../assets/images/svg/info.svg";
import { DatePickers, SelectFilter,CheckLabel } from '../../Components';
import Modal from "react-modal";
import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify'; 
import Skeleton from 'react-loading-skeleton';

const Title = ({ icon, title, showInfo, titleBtn, onDateChange, onReset , tooltip ,url,dashboardData, selectedFilters, setSelectedFilters,searchQuery,setSearchQuery,callChangeReportFilters, reportObject ,loading}) => {

    const dataSection = dashboardData
    ? dashboardData?.find((item) => item.section === "Regulation List" || item.section === "Amendment List" || item.section == "Reports List" || item.section == "Reminders List" || item.section == "Search List" || item.section == "Notes List")
    : null;

  const [areas_of_law, setAreasofLaw] = useState([]);
  const [topics, setTopics] = useState([]);
  const [jurisdictions, setJurisdictions] = useState([]);
  const pattern = /^\/dashboard\/reports\/\w+$/;
  const lastSegment = window.location.pathname.split('/').filter(Boolean).pop();



  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };  

  useEffect(() => {
    const areasOfLaw = dataSection?.data?.content?.areas_of_law || [];
    const topics = dataSection?.data?.content?.topics || [];
    const jurisdictions = dataSection?.data?.content?.jurisdictions || [];
    setAreasofLaw(areasOfLaw);
    setTopics(topics);
    setJurisdictions(jurisdictions);
    console.log("dashboardData:",dashboardData)
  }, [dataSection]);

    // check if filter already exist 
    const handleExist = (field, name) => {
        let isChecked = false;
        if (selectedFilters && selectedFilters[field]) {
          if (selectedFilters[field].includes(name)) {
            isChecked = true;
          }
        } else {
          isChecked = false;
        }
    
        return isChecked;
      }
    
    
      // This function for update report only   
      const handleApplyFilters = async () => {
    
        if (selectedFilters && selectedFilters.area_of_laws) {
          reportObject['query']['area_of_laws'] = selectedFilters.area_of_laws.join(",");
        }
        if (selectedFilters && selectedFilters.topics) {
          reportObject['query']['topics'] = (selectedFilters.topics.join(","));
        }
        if (selectedFilters && selectedFilters.jurisdiction) {
          reportObject['query']['jurisdiction'] = (selectedFilters.jurisdiction.join(","));
        }
    
        const apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report`;
        const data = reportObject;
        try {
          const apiKey = Cookies.get('api_key');
          const apiSecret = Cookies.get('api_secret');
          const response = await axios.put(apiUrl, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `token ${apiKey}:${apiSecret}`,
            },
          });
          if (response.data && response.data.status_code === 200) {
            toast.success('Report updated successfully.');
            callChangeReportFilters();
          } else {
            toast.error('Failed to update this report.');
          }
        } catch (error) {
          toast.error('Failed to update this report.');
        }
      }


      let isChecking = false; // Flag to track ongoing check operation

      const handleCheckboxChange = async (type, name) => {
        if (isChecking) {
          return; // Do nothing if a check operation is already in progress
        }
    
        isChecking = true; // Set the flag to indicate an ongoing check operation
    
        try {
          await new Promise((resolve) => setTimeout(resolve)); // Wait for the next event loop iteration
    
          setSelectedFilters((prevSelectedFilters) => {
            const updatedFilters = { ...prevSelectedFilters };
    
            if (!updatedFilters[type]) {
              updatedFilters[type] = [];
            }
    
            if (updatedFilters[type].includes(name)) {
              updatedFilters[type] = updatedFilters[type].filter(
                (filter) => filter !== name
              );
            } else {
              updatedFilters[type].push(name);
            }
    
            return updatedFilters;
          });
        } finally {
          isChecking = false; // Reset the flag after the check operation is completed
        }
      };


    const showIcon = !!icon; // Check if icon exists
    const [isContentVisible, setIsContentVisible] = useState(false);
    const titleBtnRef = useRef(null);
    const titleBtnContentRef = useRef(null);
    const location = useLocation();



    const handleTitleBtnClick = () => {
        setIsContentVisible(!isContentVisible);
    };

    const handleClickOutside = (event) => {
        if (
            titleBtnRef.current && !titleBtnRef.current.contains(event.target) &&
            titleBtnContentRef.current && !titleBtnContentRef.current.contains(event.target)
        ) {
            setIsContentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    // Check if the URL ends with "dashboard"
    const isDashboard = location.pathname.endsWith('/dashboard');

    return (
      <div className={`title-container ${!isDashboard ? "title-fixed" : ""}`}>
        <div className="title">
          {showIcon && <img className="title-icon" src={icon} alt="" />}
          <h1 className="title-h">{title}</h1>
          {showInfo && <img src={info} data-tooltip-id={title} />}
          <Tooltip
            className="info-tooltip"
            // key={title}
            id={title}
            place="top"
            content={tooltip ?? "Hello world! I'm a Tooltip"}
          />
        </div>
        <div
          className="title-btn-content"
          style={{ display: isContentVisible ? "block" : "none" }}
          ref={titleBtnContentRef}
        >
          <DatePickers
            inputType={"datetime"}
            onDateChange={onDateChange}
            onReset={onReset}
          />
          <div className='filters-sm'>
          {
              lastSegment !== "search" && (
                <>
                  <h1 className="filter-title">Search</h1>
                  <input
                    onChange={handleSearchChange}
                    type="search"
                    className="filter-sidebar-search"
                    placeholder="Search"
                    value={searchQuery}
                  />
                </>
              )}

            { (loading ||(areas_of_law && areas_of_law.length>0 )) && <h1 className="filter-title filter-title-sm">Area of law</h1>}

            {loading ? (
              <>
                <div style={{ width: "100%" }}>
                   <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                  <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                  <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                </div>
              </>
            ) :
              (
                <div className='results-reports'>
                  {<>
                    {areas_of_law.map((item, index) => (
                      <CheckLabel
                        key={index}
                        value={item}
                        name={item.name}
                        checked={handleExist("area_of_laws", item.name)}
                        // count={"50"}
                        selectedFilters={selectedFilters.areas_of_law || []}
                        onCheckboxChange={() =>
                          handleCheckboxChange("area_of_laws", item.name)
                        }
                      />
                    ))}
                  </>

                  }
                </div>
              )}

            { (loading ||(topics && topics.length>0 )) && <h1 className="filter-title filter-title-sm">Topic</h1>}


            {loading ? (
              <>
                <div style={{ width: "100%" }}>
                  <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                  <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                  <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                </div>
              </>
            ) :
              (
                <div className='results-reports'>
                  {<>
                    {topics.map((item, index) => (
                      <CheckLabel
                        key={index}
                        value={item}
                        name={item.name}
                        // count={"20"}
                        checked={handleExist("topics", item.name)}
                        selectedFilters={selectedFilters.topics || []}
                        onCheckboxChange={() =>
                          handleCheckboxChange("topics", item.name)
                        }
                      />
                    ))}
                  </>

                  }
                </div>
              )}


          { (loading ||(jurisdictions && jurisdictions.length>0 )) && <h1 className="filter-title filter-title-sm">Jurisdiction</h1>}


            {loading ? (
              <>
                <div style={{ width: "100%" }}>
                  <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                  <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                  <Skeleton animation="wave" height={16} width={'100%'} className="custom-skeleton" />
                </div>
              </>
            ) :
              (
                <div className='results-reports'>
                  {<>
                    {jurisdictions.map((item, index) => (
                      <CheckLabel
                        key={index}
                        value={item}
                        name={item.name}
                        // count={"20"}
                        checked={handleExist("jurisdictions", item.name)}
                        selectedFilters={selectedFilters.jurisdictions || []}
                        onCheckboxChange={() =>
                          handleCheckboxChange("jurisdictions", item.name)
                        }
                      />
                    ))}
                  </>

                  }
                </div>
              )}



            {window.location.pathname.match(pattern) && <button className='apply-btn' onClick={() => handleApplyFilters()}>
              Apply Filters
            </button>}
            </div>
        </div>
        {titleBtn && (
          <div
            className="title-btn"
            onClick={handleTitleBtnClick}
            ref={titleBtnRef}
          ></div>
        )}
      </div>
    );
}

export default Title;
