import React from 'react'
import "./Features.css"
import { Container } from 'react-bootstrap'
import { Title, LinkBtn } from '../../../../Components'
import arrowDown from "../../../../Assets/images/svg/arrow-down.svg"

const Features = ({url,featuresSection}) => {

    const { features_title, feauters_discription, feauters_video, price_btn}= featuresSection.data;

    return (
        <Container className='features-container'>
            <Title title={features_title} />
            <p className='features-para' data-aos="fade-down" data-aos-duration="2000"> {feauters_discription} </p>
            <div className='features-video' data-aos="fade-down" data-aos-duration="1500" >
                <video src={url+"/"+ feauters_video} autoPlay muted loop />
                <img src={arrowDown} />
            </div>

            <div className='features-link' data-aos="fade-down" data-aos-duration="1500">
                <LinkBtn
                    title={price_btn}
                    color={"#fff"}
                    bg={"var(--main-color)"}
                    to = "/pricing"
                />
            </div>

        </Container>
    )
}

export default Features
