import React from 'react'
import "../BookDemo/BookDemo.css"
import "./Trail.css"
import { Hero } from '../../Components'
import { Container, Row, Col } from 'react-bootstrap'
import PhoneInput from "react-phone-number-input";
import { RegisterForm } from '../../Components/index';


function Trail({url, data}) {


 

  return (
    <div>
        <Hero 
            mainTitle={"Home /"}
            title={"Free Trail"}
            para={""}
        />
        <div className='book-demo-container'>
          <Container >
            <Row>
              <Col lg={6}>
                <h1 className='book-demo-title'>BOOK your <span>demo</span></h1>
                <p className='book-demo-para'>Book a demo with our team and see how Polaris can modernize your organization. All demos include a free trial!</p>
                <img src={require("../../Assets/images/bookdemo/Group 8126.png")} className='book-demo-image' />
              </Col>
              <Col lg={1}></Col>
              <Col lg={5}>
                <RegisterForm url={url} data={data}></RegisterForm>
              </Col>
            </Row>
          </Container>
        </div>
       
    </div>
  )
}

export default Trail