import React from 'react'
import "./AddResultBtn.css"
import addBtn from "../../assets/images/svg/addbtn.svg"

const AddResultBtn = () => {
    return (
        <button className='add-result'><img src={addBtn} />Add This Results to Report</button>
    )
}

export default AddResultBtn
