import React, { useState, useEffect } from "react";
import "./Amendments.css";
import { Title, Text, DatePickers, AmendmentsNote } from "../../../../Components";
import amendment from "../../../../assets/images/svg/amendments.svg";
import arrowRight from "../../../../assets/images/svg/arrowRight.svg";
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap'; // Assuming you are using React-Bootstrap
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";


const Amendments = ({ url, data }) => {
  const [amendments, setAmendments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isClear, setIsClear] = useState(false);

  const dashboardSection = data
    ? data?.find((item) => item.section === "Dashboard")
    : null;

  useEffect(() => {
    if (
      dashboardSection &&
      dashboardSection.data &&
      dashboardSection.data.content &&
      dashboardSection.data.content.amendments &&
      dashboardSection.data.content.amendments.objects
    ) {
      const amendmentsObjects = dashboardSection.data.content.amendments.objects.map(item => {
        return {
          ...item,
        };
      });

      setAmendments(amendmentsObjects);
      setLoading(false);
    }
  }, [dashboardSection]);


  const handleResetDate = () => {
    setSelectedDate(null); // Reset the selected date
    setIsClear(true);
  };

  const [selectedDate, setSelectedDate] = useState({ startDate: null, endDate: null });

  const handleDateChange = (date) => {
      if (!date || !date.startDate || !date.endDate) {
          // Handle the case where date or startDate or endDate is null
          return;
   }
    
      // Function to format date as "Y-%m-%d %H:%M:%S"
      const formatDate = (inputDate) => {
        if (!inputDate) return null;
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const day = inputDate.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
      };
    
      // Set time to midnight for the start date and format it
      const startDateTime = date.startDate ? formatDate(new Date(date.startDate)) : null;
    
      // Set time to midnight for the end date and format it
      const endDateTime = date.endDate ? formatDate(new Date(date.endDate)) : null;
    
      setSelectedDate({
        startDate: startDateTime,
        endDate: endDateTime
      });
    };
 
    useEffect(() => {
      if ((selectedDate && (selectedDate.startDate !== null || selectedDate.endDate !== null)) || (isClear && isClear === true )) {
        fetchData();
      }
    }, [selectedDate,isClear]);
  
    const fetchData = async () => {
      try {   

        let apiUrl = `${url}/api/method/projectx.projectx.doctype.recent_amendment.recent_amendment.amendment`;
        if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
          apiUrl += `?&amendmentdate_start=${selectedDate.startDate}&amendmentdate_end=${selectedDate.endDate}`;
        }
        const apiKey = Cookies.get('api_key');
        const apiSecret = Cookies.get('api_secret');
    
        setLoading(true); // Start loading
        const response = await axios.get(apiUrl,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: `token ${apiKey}:${apiSecret}`,
        },
        });

        if (response.data.status_code !== 200) {
          toast.error("Failed to fetch data. Please try again..");
        }
        
        const result = response.data.result;
        setAmendments(result?.objects?.slice(0, 3) ?? []);    
        setLoading(false); // End loading
      } catch (error) {
        toast.error("Failed to fetch data. Please try again..");
        console.error("Error fetching data:", error);
        setLoading(false); // End loading
      }
    };

  

  return (
    <div className="reminders">

      <Title
        icon={amendment}
        title={"Amendments"}
        showInfo={true}
        titleBtn={true}
        onDateChange={handleDateChange}
        onReset={handleResetDate}
        tooltip={`"Amendments" provide information about changes to legislation that the government has announced or passed.`} 
        //  It covers the previous 60 days, but you can change the date range to cover longer or shorter periods "todo"
      />
                <div className="mt-3 hide-responsive">
        <Text para={"By data range:"} />
      </div>
      <div className="mt-2 hide-responsive">
        <DatePickers
         inputType={"datetime"} 
         onDateChange={handleDateChange}
         onReset={handleResetDate}  
         />
      </div>
      {/* Date picker and other UI components */}
      
      <div className="note-container">
        {loading ?  (
          <>
            <div className="reminder-skelton">
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={18} />
                <Skeleton width={"100%"} height={13} />
              </div>
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={15} />
                <Skeleton width={"90%"} height={13} />
                <Skeleton width={"103%"} height={15} />
                <Skeleton width={"90%"} height={13} />
              </div>
            </div>
            <br />
            <div className="reminder-skelton">
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={18} />
                <Skeleton width={"100%"} height={13} />
              </div>
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={15} />
                <Skeleton width={"95%"} height={13} />
                <Skeleton width={"103%"} height={15} />
                <Skeleton width={"90%"} height={13} />
              </div>
            </div>
          </>
        ) : (
          <div className="dashboard-reminders">
            {amendments.length === 0 ? (
              <div className="not-found">No data available.</div>
            ) : (
              <>
                {amendments.map((amendment, index) => (
                  <AmendmentsNote 
                    key={index} 
                    value={amendment.name}
                    textTitle={amendment.areas_of_laws
                        ? amendment.areas_of_laws
                            .split(",")
                            .map((area) => area.trim())
                            .join(", ")
                        : ""}
                    noteCount={amendment.notes_count}
                    reminderCount={amendment.reminders_count}
                    reportCount={"0"}
                    docName={amendment.federal_regulation}
                    url={url}
                    date={amendment.amendmentdate}
                    textDate={"in Force Date"}
                    title={amendment.amendment_citation}
                    onRefresh={fetchData}
                    titleLink={`/dashboard/regulation/${encodeURIComponent(amendment.federal_regulation)}`}
                  />
                ))}
          
                <div className="note-link-container">
                    <a href={"/dashboard/amendments"} className="note-link">
                        Go To Amendments List <img src={arrowRight} alt="arrow" />
                    </a>
                </div>

                
                
              </>
            )}
          </div>
        )}
      </div>
    
    </div>
  );
};

export default Amendments;
