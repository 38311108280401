import React, { useState, useRef, useEffect } from 'react';
import './DatePickers.css';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import clock from "../../assets/images/svg/clock.svg";
import resetIcon from "../../assets/images/svg/close.svg"; 

const DatePickers = ({ inputType,onDateChange ,onReset}) => {

  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);





  const [inputValue, setInputValue] = useState('');
  const [showResetButton, setShowResetButton] = useState(false);

  const calendarRef = useRef(null);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    setShowCalendar(false);

    if (onDateChange) {
      const selectedDate = ranges.selection;
      onDateChange(selectedDate);
      // onDateChange(ranges.selection); // Pass the entire date range object
    }
  };


  



  const handleInputClick = () => {
    setShowCalendar(true);
    setShowResetButton(true);
  };

  const handleCalendarBlur = (event) => {
    if (calendarRef.current && calendarRef.current.contains(event.relatedTarget)) {
      return;
    }

    if (!dateRange[0].startDate && !dateRange[0].endDate) {
      setShowResetButton(false);
    }
    
    setShowCalendar(false);
    // setShowCalendar(false);
    // setShowResetButton(false);
  };

  const handleResetClick = () => {
    if(onReset){
    onReset();
    }
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ]);
    setInputValue('');
    setShowCalendar(false);
    setShowResetButton(false);
    if (onDateChange) {
      onDateChange(null);
    }
  };
  
  const formatValue = (startDate, endDate) => {
    if (inputType === 'datetime') {
      return `${startDate.toDateString()} - ${endDate.toDateString()}`;
    } else {
      return startDate.toDateString();
    }
  };

  // useEffect(() => {
  //   const { startDate, endDate } = dateRange[0];
  //   if (startDate && endDate) {
  //     setInputValue(formatValue(startDate, endDate));
  //   } else if (startDate) {
  //     setInputValue(formatValue(startDate, startDate));
  //   } else {
  //     setInputValue('Start - End Date: All-time');
  //   }

  //   setShowResetButton(!!startDate); // Set showResetButton based on the presence of a startDate
  // }, [dateRange, inputType]);

  useEffect(() => {
    const { startDate, endDate } = dateRange[0];
    
    if (startDate && endDate) {
      setInputValue(formatValue(startDate, endDate));
    } else if (startDate) {
      setInputValue(formatValue(startDate, startDate));
    } else {
      setInputValue('Start - End Date: All-time');
    }
  
    setShowResetButton(!!startDate || showCalendar); // Set showResetButton based on the presence of a startDate or if the calendar is shown
  }, [dateRange, inputType, showCalendar]);

  const datepickerStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(100% - 10px)',
    backgroundColor: '#fff',
  };

  

  if (showCalendar) {
    datepickerStyle.backgroundImage = 'none';
  } else if (inputType === 'datetime' && dateRange[0].startDate && dateRange[0].endDate) {
    datepickerStyle.backgroundImage = 'none';
  } else if (inputType !== 'datetime') {
    datepickerStyle.backgroundImage = 'none';
  } else {
    datepickerStyle.backgroundImage = `url(${clock})`;
  }

  const datepickerContainerClasses = showCalendar && inputType !== 'datetime' ? 'hide-range-styles' : '';

  return (
    <div className={`datepicker-container ${datepickerContainerClasses}`}>
      <div className="date-picker-wrapper">
        <input
          className="date-picker"
          type="text"
          value={inputValue}
          readOnly
          onClick={handleInputClick}
          onBlur={handleCalendarBlur}
          style={datepickerStyle}
        />
        {showResetButton &&
          !showCalendar && ( // Only render the reset button if showResetButton is true and showCalendar is false
            <button className="reset-button" onClick={handleResetClick}>
              <img src={resetIcon} alt="Reset" />
            </button>
          )}
      </div>
      {showCalendar && (
        <div ref={calendarRef} >
          <DateRangePicker
            ranges={dateRange}
            onChange={handleSelect}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            editableDateInputs={true}
            
          />
        </div>
      )}
    </div>
  );
};

export default DatePickers;