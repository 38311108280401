import React from 'react';
import './RemainingDays.css';

const RemainingDays = ({ days, hours }) => {
  let dayText = 'days';
  let hourText = 'hours';
  let timeText = 'left and';
  let remainingDaysStyle = {
    color: '#1B2559',
  };

  if (days === 0) {
    dayText = '';
    timeText = '';
  } else if (days < 0) {
    days = Math.abs(days);
    timeText = 'ago';
    if (days === 1) {
      dayText = 'day';
    }
  } else if (days === 1) {
    dayText = 'day';
  } 

  if (hours === 1) {
    hourText = 'hour';
  }

  if (days < 5) {
    remainingDaysStyle.color = '#EC5252';
  }

  return (
    <div className="remaining-days" style={remainingDaysStyle}>
      {days > 0 && `${days} ${dayText} `}
      {days > 0 && timeText && `${timeText} `}
      {hours > 0 && `${hours} ${hourText}`}
    </div>
  );
};

export default RemainingDays;