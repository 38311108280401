import React from 'react'
import "./PaymentPage.css"
import { ContactUs } from '../../Components'
import { Container, Row, Col } from "react-bootstrap"
import { Payment } from "../../Features"

const PaymentPage = () => {
    return (
        <div className='payment-page'>
            <Container>
                <Payment></Payment>


            </Container>
        </div>
        
    )
}

export default PaymentPage