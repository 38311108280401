import React from 'react'
import "./UnderDevelopment.css"
import warning from "../../assets/images/svg/warningPopup.svg"

const UnderDevelopment = ({ btnClick }) => {
  return (
    <div className='popup'>
        <div className='popup-container'>
            <img src={warning} />
            <div className='popup-content'>
                <h1 className='popup-title'>Note</h1>
                <p className='popup-para'>feature under development</p>
            </div>
        </div>
        <button className='popup-btn' onClick={btnClick}>Close</button>
    </div>
  )
}

export default UnderDevelopment