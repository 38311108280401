import React from 'react'
import "./TeamCards.css"
import { Row, Col } from 'react-bootstrap'
import { TeamCard } from "../../index"

const TeamCards = ({url ,team}) => {

  return (
    <Row className='team-cards'>
        {team.map((member, index) => (
            <Col lg={4} sm={6}>
                <TeamCard
                    image={url + "/" + member.image}
                    para={member.description}
                    title={member.member_name}
                    secondTitle={member.specialization}
                />
            </Col>
        ))}
    </Row>
  )
}

export default TeamCards