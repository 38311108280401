import React, { useState, useEffect } from "react";
import "./Results.css";
import { Report } from "../../index";
import axios from "axios";
import Cookies from "js-cookie";
import { Paginations, Debounce } from "../../../../Components";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

const Results = ({
  url,
  selectedFilters,
  selectedDate,
  searchQuery,
  dashboardData,
  clearedDate,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const resultsPerPage = 20; // Set results per page
  // const [initialLoad, setInitialLoad] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const debouncedSearchQuery = Debounce(searchQuery, 500); // 500ms debounce delay

  useEffect(() => {
    // Extract the initial data from dashboardData
    // if (initialLoad) {
    const amendmentSection = dashboardData?.find(
      (section) => section.section === "Amendment List"
    );
    if (amendmentSection) {
      const initialData = amendmentSection.data.content.amendments.objects;
      const pagination =
        amendmentSection.data.content.amendments.pagination.metadata;
      setData(initialData);
      setCurrentPage(pagination.current_page);
      setTotalPages(pagination.total_pages);
      setLoading(false);
      // setInitialLoad(false);
      // }
    }
  }, [dashboardData]);

  useEffect(() => {
    if (
      (selectedFilters != {} &&
        selectedFilters != undefined &&
        Object.keys(selectedFilters).length > 0) ||
      (selectedDate &&
        (selectedDate.startDate !== null || selectedDate.endDate !== null)) ||
      (clearedDate && clearedDate === true)
    ) {
      fetchData();
    }
  }, [selectedFilters, selectedDate, clearedDate]);

  useEffect(() => {
    if (debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [debouncedSearchQuery]);

  useEffect(() => {
    if (searchQuery === "" && debouncedSearchQuery.length > 0) {
      fetchData();
    }
  }, [searchQuery]);

  const fetchData = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const filterParams = Object.keys(selectedFilters)
        .filter((type) => selectedFilters[type].length > 0)
        .map((type) => {
          if (Array.isArray(selectedFilters[type])) {
            return `${type}=${encodeURIComponent(
              selectedFilters[type].join(",")
            )}`;
          } else {
            return `${type}=${encodeURIComponent(selectedFilters[type])}`;
          }
        })
        .join("&");
      const start = (pageNumber - 1) * resultsPerPage;
      let apiUrl = `${url}/api/method/projectx.projectx.doctype.recent_amendment.recent_amendment.amendment?start=${start}&limit=${resultsPerPage}${
        searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ""
      }`;
      if (filterParams) {
        apiUrl += `&${filterParams}`;
      }
      if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
        apiUrl += `&amendmentdate_start=${selectedDate.startDate}&amendmentdate_end=${selectedDate.endDate}`;
      }
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });
      if (response.data.status_code !== 200) {
        toast.error("Failed to fetch data. Please try again..");
      }
      const result = response.data.result;
      setCurrentPage(result.pagination.metadata.current_page);
      setTotalPages(result.pagination.metadata.total_pages);
      setData(result.objects);
      setLoading(false);
    } catch (error) {
      toast.error("Failed to fetch data. Please try again..");
      setLoading(false);
    }
  };

  const onPageChange = (pageNumb) => {
    fetchData(pageNumb);
  };


  useEffect(() => {
    // console.log(showAmendmentsBtns);

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check initial screen width on component mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };



  }, []);


  return (
    <div className="results">
      {loading ? (
        <>
          <div className="reminder-skelton">
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={18} />
              <Skeleton width={"100%"} height={13} />
            </div>
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={15} />
              <Skeleton width={"90%"} height={13} />
              <Skeleton width={"103%"} height={15} />
              <Skeleton width={"90%"} height={13} />
            </div>
          </div>
          <br />
          <div className="reminder-skelton">
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={18} />
              <Skeleton width={"100%"} height={13} />
            </div>
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={15} />
              <Skeleton width={"95%"} height={13} />
              <Skeleton width={"103%"} height={15} />
              <Skeleton width={"90%"} height={13} />
            </div>
          </div>
          <br />
          <div className="reminder-skelton">
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={18} />
              <Skeleton width={"100%"} height={13} />
            </div>
            <div className="first-reminder-skelton">
              <Skeleton width={"100%"} height={15} />
              <Skeleton width={"95%"} height={13} />
              <Skeleton width={"103%"} height={15} />
              <Skeleton width={"90%"} height={13} />
            </div>
          </div>
        </>
      ) : (
        <>
          {data.length === 0 ? (
            <div className="not-found">There are no amendments.</div>
          ) : (
            <>
              {data.map((item, index) => {
                const dateToShow = item.amendmentdate
                  ? item.amendmentdate
                  : item.date;
                const titleToShow = item.amendment_citation
                  ? item.amendment_citation
                  : item.citation;

                return (
                  <Report
                    key={index}
                    url={url}
                    date={dateToShow}
                    textDate={"in Force Date"}
                    title={titleToShow}
                    textTitle={
                      item.areas_of_laws
                        ? item.areas_of_laws
                            .split(",")
                            .map((area) => area.trim())
                            .join(", ")
                        : ""
                    }
                    title2={""}
                    para={""}
                    showButton={false}
                    noteCount={item.notes_count}
                    reminderCount={item.reminders_count}
                    docName={item.name}
                    federalRegulation={item.federal_regulation}
                    titleLink={`/dashboard/regulation/${encodeURIComponent(
                      item.federal_regulation
                    )}`}
                    onRefresh={fetchData}
                    isSmallScreen={isSmallScreen}
                  />
                );
              })}
              <div className="d-flex justify-content-center mt-5">
                <Paginations
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Results;
