import React, { useState, useEffect } from "react";
import "./CompanyProfile.css";
import ProfileInfo from "../ProfileInfo/ProfileInfo";
import PhoneInput from "react-phone-number-input";
import Cookies from 'js-cookie';
import { SpinnerLoading } from "../../../../Components"; // Ensure this component is imported correctly
import { toast } from 'react-toastify';

const CompanyProfile = ({ url, template }) => {
  const companyProfileData = template?.data?.content?.company_profile || {};

  const [checkedItems, setCheckedItems] = useState({});
  const [prevCheckedItems, setPrevCheckedItems] = useState({});
  const [companyProfile, setCompanyProfile] = useState({
    company_name: companyProfileData.customer_name || "",
    email: companyProfileData.email_id || "",
    phone_number: companyProfileData.mobile_no || "",
    company_image: companyProfileData.image || "", // Using 'image' field initially
  });

  const [editedCompanyProfile, setEditedCompanyProfile] = useState({
    company_name: companyProfileData.customer_name || "",
    email: companyProfileData.email_id || "",
    phone_number: companyProfileData.mobile_no || "",
    company_image: companyProfileData.image || "", // Using 'image' field initially
  });

  const [companyPhoto, setCompanyPhoto] = useState(null);
  const [companyPhotoPreview, setCompanyPhotoPreview] = useState(companyProfileData.image ? `${url}${companyProfileData.image}` : null);
  const [isEdited, setIsEdited] = useState(false); // State to track if there are changes
  const [isEditedCheckList, setIsEditedCheckList] = useState(false); // State to track if there are changes
  const [isLoading, setIsLoading] = useState(false); // Loading state


  useEffect(() => {
    setPrevCheckedItems(checkedItems);
  }, []);

  // useEffect(() => {
  //   if (companyProfileData.image) {
  //     setCompanyPhoto({
  //       filename: companyProfileData.image,
  //       data: null // No base64 data initially
  //     });
  //   }
  // }, [companyProfileData.image]);

  const handleChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems[name] !== checked) {
        setIsEditedCheckList(true); // Set isMod to true if any modification is made
      }
      return {
        ...prevCheckedItems,
        [name]: checked,
      };
    });
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedCompanyProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
    checkIfEdited(name, value);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyPhoto(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setCompanyPhotoPreview(reader.result);
        setIsEdited(true);
      };
      reader.readAsDataURL(file);
    } else {
      setCompanyPhoto(null);
    }
  };

  const checkIfEdited = (name, value) => {
    if (
      (name === "company_name" && value !== companyProfile.company_name) ||
      (name === "email" && value !== companyProfile.email) ||
      (name === "phone_number" && value !== companyProfile.phone_number) ||
      companyPhoto
    ) {
      setIsEdited(true);
    } else {
      const { company_name, email, phone_number } = editedCompanyProfile;
      if (
        company_name === companyProfile.company_name &&
        email === companyProfile.email &&
        phone_number === companyProfile.phone_number &&
        !companyPhoto
      ) {
        setIsEdited(false);
      }
    }
  };

  const handleCancel = () => {
    setEditedCompanyProfile({
      company_name: companyProfile.company_name,
      email: companyProfile.email,
      phone_number: companyProfile.phone_number,
      company_image: companyProfile.company_image,
    });
    setCompanyPhoto(null);
    setCompanyPhotoPreview(companyProfileData.image ? `${url}${companyProfileData.image}` : null);
    setIsEdited(false);
  };


  const handleSave = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading
    try {
      const apiKey = Cookies.get('api_key');
      const apiSecret = Cookies.get('api_secret');
      const formData = new FormData();

      formData.append('company_name', editedCompanyProfile.company_name);
      formData.append('email', editedCompanyProfile.email);
      formData.append('phone_number', editedCompanyProfile.phone_number);

      if (companyPhoto) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          setIsLoading(true); // Start loading after file is read
          const photoData = {
            filename: companyPhoto.name,
            data: reader.result.split(',')[1]
          };

          formData.append('logo', JSON.stringify(photoData));

          const response = await fetch(`${url}/api/method/erpnext.selling.doctype.customer.customer.company`, {
            method: 'PUT',
            headers: {
              'Authorization': `token ${apiKey}:${apiSecret}`,
            },
            body: formData,
          });

          if (!response.ok) {
            const data = await response.json();
            toast.error(data.message);
            handleCancel();
            setIsLoading(false);
            return;
          }
          const data = await response.json();
          setCompanyProfile({
            ...editedCompanyProfile,
            company_image: `/files/${photoData.filename}`
          });
          setIsEdited(false);
          setIsLoading(false); // End loading after success
        };
        reader.readAsDataURL(companyPhoto);
      } else {
        const response = await fetch(`${url}/api/method/erpnext.selling.doctype.customer.customer.company`, {
          method: 'PUT',
          headers: {
            'Authorization': `token ${apiKey}:${apiSecret}`,
          },
          body: formData,
        });

        if (!response.ok) {
          const data = await response.json();
          toast.error(data.message);
          handleCancel();
          setIsLoading(false);
          return;
        }

        const data = await response.json();
        setCompanyProfile(editedCompanyProfile);
        setIsEdited(false);
        setIsLoading(false); // End loading after success
      }
    } catch (error) {
      toast.error(error); 
      handleCancel();
      setIsLoading(false); // End loading after error
    }
  };

  const data = [
    { id: 1, label: "Manitoba" },
    { id: 2, label: "Manitoba" },
    { id: 3, label: "Manitoba" },
    { id: 4, label: "Northwest Territories" },
    { id: 5, label: "Manitoba" },
    { id: 6, label: "Manitoba" },
    { id: 7, label: "Manitoba" },
    { id: 8, label: "Northwest Territories" },
    { id: 9, label: "Manitoba" },
    { id: 10, label: "Nova Scotia" },
    { id: 11, label: "Nova Scotia" },
    { id: 12, label: "Nova Scotia" },
    { id: 13, label: "Nova Scotia" },
    // Add more items as needed
  ];

  const handleCancelCheckList = () => {
    setCheckedItems(prevCheckedItems)
    setIsEditedCheckList(false);
  };

  useEffect(() => {
    checkIfEdited("phone_number", editedCompanyProfile.phone_number);
  }, [editedCompanyProfile.phone_number]);

  return (

    isLoading ? (
      <div className="profile-page-loading-container">
        <div className="profile-page-loading">
          <SpinnerLoading />
        </div>
      </div>
    ) : (
      <div>
        <ProfileInfo
          name={companyProfile.company_name}
          email={companyProfile.email}
          ProfilePhoto={companyPhotoPreview || (companyProfile.company_image ? url + companyProfile.company_image : require("../../../../assets/images/svg/company-logo.png"))}
          onPhotoChange={handlePhotoChange}
          photoName={"Company Photo"}
        />
        <div>
          <div className="-tab profile-form2">
            <div className="user-edit">
              <span className="tab-photo">Company Details</span>
            </div>
            <form className="row new-profile-form">
              <div className="col-lg-4 top-label">
                <div className="form-group">
                  <label className="profile-label" htmlFor="tbCompanyName">
                    Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control profile-form-input"
                    id="tbCompanyName"
                    name="company_name"
                    value={editedCompanyProfile.company_name}
                    onChange={handleInputChange}
                    placeholder="Company Name"
                  />
                </div>
              </div>
              {/* <div className="col-lg-4 top-label">
              <div className="form-group">
                <label className="profile-label" htmlFor="tblocation">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control profile-form-input"
                  id="tblocation"
                  name="location"
                  value={editedCompanyProfile.location}
                  onChange={handleInputChange}
                  placeholder="Location"
                />
              </div>
            </div> */}
              <div className="col-lg-4 top-label">
                <div className="form-group">
                  <label className="profile-label" htmlFor="tbEmail">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control profile-form-input"
                    id="tbEmail"
                    name="email"
                    value={editedCompanyProfile.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group top-label">
                  <label className="profile-label" htmlFor="tbPhoneNumber">
                    Phone Number
                  </label>
                  <PhoneInput
                    type="tel"
                    className=""
                    placeholder="Phone Number"
                    value={editedCompanyProfile.phone_number}
                    onChange={(value) =>
                      setEditedCompanyProfile((prevProfile) => ({
                        ...prevProfile,
                        phone_number: value,
                      }))
                    }
                  />
                </div>
              </div>
              {isEdited && (
                <div className="button-container">
                  <a className="btn-user-details" style={{ color: "#828282" }} onClick={handleCancel}>
                    Cancel
                  </a>
                  <a onClick={handleSave} className="btn-user-details" style={{ paddingLeft: "30px", cursor: "pointer" }}>
                    Save
                  </a>
                </div>
              )}
            </form>
          </div>
        </div>

        {/* <div>
        <div className="-tab profile-form2">
          <div className="user-edit">
            <span className="tab-photo">Provinces and Territories List</span>
          </div>
          <div>
            <Row>
              {data.map((item) => (
                <Col lg={3} style={{ paddingTop: "20px" }} key={item.id}>
                  <label className="provinces-label">
                    <input
                      type="checkbox"
                      name={item.id}
                      checked={checkedItems[item.id] || false}
                      onChange={handleChange}
                      className="custom-checkbox"
                    />
                    {item.label}
                    <span className="checkmark"></span>
                  </label>
                </Col>
              ))}
            </Row>
          </div>

          {isEditedCheckList && (<div className="button-container">
            <a className="btn-user-details" style={{ color: "#828282" }} onClick={handleCancelCheckList}>
              Cancel
            </a>
            <a  className="btn-user-details" style={{ paddingLeft: "30px" }}>
              Save
            </a>
          </div>
          )}
        </div>
      </div> */}
      </div>)
  );
};

export default CompanyProfile;
