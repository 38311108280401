import React, { useState, useEffect } from 'react'
import "./RenameReport.css"
import notes from "../../assets/images/svg/notes.svg"
import Cookies from 'js-cookie';
import axios from 'axios';
import { ConfirmAdd } from '../../Components';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import edit from "../../assets/images/svg/edit.svg"
const RenameReport = ({ url, name, cancelBtn, onSuccess, topic, note, filters }) => {

    const [remportName, setReportName] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [query, setQuery] = useState({});

    useEffect(() => {
        const updatedQuery = {};
        // let withPagination = 0;

        filters.forEach(filter => {
            updatedQuery[filter.field] = filter.value;
        });

        // if (updatedQuery['start'] && updatedQuery['limit']) {
        //     withPagination = 1;
        // }

        // updatedQuery['with_pagination'] = withPagination;

        setQuery(updatedQuery);

    }, [filters]);





    const confirmRename = async () => {

        const apiUrl = `${url}/api/method/projectx.projectx.doctype.law_report.law_report.law_report`;
        const data = {
            "name": name,
            "report_name": remportName,
            "topic": topic,
            "note": note,
            "query": query
        };

        try {
            const apiKey = Cookies.get('api_key');
            const apiSecret = Cookies.get('api_secret');
            const response = await axios.put(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `token ${apiKey}:${apiSecret}`,
                },
            });
            if (response.data && response.data.status_code === 200) {
                toast.success('Report rename successfully.');
                onSuccess();
            } else {
                toast.error('Failed to rename the report.');
            }
        } catch (error) {
            toast.error('Failed to rename the report.');
        }
        setShowPopup(false);
        cancelBtn();
    };
    const cancelRename = () => {
        setShowPopup(false);
    };
    const handleRename = () => {
        if (remportName.trim() === '') {
            setErrorMessage('Please Add a New Report Name');
            setShowPopup(false);
        }
        else {
            setShowPopup(true);
        }

    };

    return (
        <div className='add-report-container'>
            <div className='add-report-header'>
                <div className='d-flex align-items-center'>
                    <img className='add-report-img' src={notes} />
                    <h1 className='add-report-title'>Rename the report</h1>
                </div>

            </div>
            <div className='add-report-inputs'>
                <input type='text' className='add-report-text' placeholder='Your report name' onChange={(event) => setReportName(event.target.value)} />

                <button className='add-report-button' onClick={() => handleRename()}>Rename</button>
            </div>
            {errorMessage && (
                <div className="popup-error">
                    <p>{errorMessage}</p>
                </div>
            )}
            <div className='add-report-btns'>
                <button className='add-report-cancle' onClick={cancelBtn}>Cancle</button>
            </div>
            <Modal
                isOpen={showPopup}
                onRequestClose={cancelRename}
                contentLabel="Confirmation Popup"
            >
                <ConfirmAdd
                    para={"Are you sure you want to rename this?"}
                    cancleAdd={cancelRename}
                    confirmAdd={confirmRename}
                    addPara={"Yes, Sure"}
                    src={edit}
                />
            </Modal>
        </div>
    )
}

export default RenameReport