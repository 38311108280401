import React, { useState } from "react";
import "./ContactUs.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Title } from "../../Components";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { LinkBtn } from "../../Components";

const ContactUs = ({url, contactSection}) => {

  const { contact_us_label, contact_us_description, contact_us_img, contact_us_btn} = contactSection ?contactSection.data: "";


  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);


  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleChangePhone = (val) => {
    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: val,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payload = {
      full_name: formData.fullName,
      email: formData.email,
      mobile_number: formData.phoneNumber,
      note: formData.message,
    };

    try {
      const response = await fetch(
        "https://dashboard.polarisai.ca/api/method/projectx.projectx.doctype.contact_us_form.contact_us_form.contact_us_form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        // Handle successful response
        setIsSubmitted(true);
      } else {
        // Handle error response
        alert("Failed to send message");
      }
    } catch (error) {
      // Handle network error
      alert("An error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div className="contact-us">
      <Container className="cotact-us-container">
        <Title title={contact_us_label ?? "GET IN TOUCH"} />
        <p className="contact-us-para" data-aos="fade-down" data-aos-duration="1500">
          {contact_us_description ??   "Streamline compliance, enhance efficiency, and navigate complex regulations effortlessly with our innovative solutions. Streamline compliance, enhance efficiency, and navig Streamline compliance,"}
        </p>
        <Row data-aos="fade-right" data-aos-duration="2300">
          <Col lg={4}>
            {isSubmitted ? (
              <div className="success-message">
                <h2>Thank you!</h2>
                <p>
                  Your message has been sent successfully. We will get back to
                  you shortly.
                </p>
              </div>
            ) : (
              <Form
                onSubmit={handleSubmit}
                style={{ opacity: isSubmitting ? 0.5 : 1 }}
              >
                <div
                  className="contact-input"
                >
                  <p className="contact-input-title">Full name</p>
                  <input
                    type="text"
                    className="contact-input-text"
                    placeholder="your name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    required
                  />
                </div>
                <div
                  className="contact-input"
                >
                  <p className="contact-input-title">Phone Number</p>
                  <PhoneInput
                    placeholder="Enter phone number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={(value) => handleChangePhone(value)}
                    country={undefined}
                    defaultCountry={"CA"}
                    international={true}
                    className="contact-input-text"
                    disabled={isSubmitting}
                    required
                  />
                </div>

                <div
                  className="contact-input"
                >
                  <p className="contact-input-title">Email Address</p>
                  <input
                    type="email"
                    className="contact-input-text"
                    placeholder="yourName@email.com"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    required
                  />
                </div>
                <div
                  className="contact-input"
                >
                  <p className="contact-input-title">Note</p>
                  <textarea
                    rows={"5"}
                    className="contact-input-text"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    required
                  ></textarea>
                </div>
                <div >
                  <input
                    type="submit"
                    value={"SEND"}
                    disabled={isSubmitting}
                    className="contact-submit"
                  />
                </div>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
      {!isSubmitted && (
        <div>
          <img
            className="contact-us-img"
            src={ contact_us_img?(url+"/"+contact_us_img): require("../../Assets/images/Home/contactus.png")}
          />
          <Container className="contact-us-img-responsive">
            <img src={ contact_us_img?(url+"/"+contact_us_img): require("../../Assets/images/Home/contactus.png")} />
          </Container>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
