import React, { useState } from 'react';
import "./Payment.css"
import { Container, Row, Col } from "react-bootstrap"
import Visa from '../../../../Assets/images/Payment/Visa.png'; 
import Paypal from '../../../../Assets/images/Payment/paypal.png'; 
import Alipay from '../../../../Assets/images/Payment/alipay.png'; 

const Payment = ({}) => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };
    return (
      <Container>
        <Row>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div>
              <div className="payment-title">
                Please Select the payment method
              </div>
              <div className="payment-text">
                Meet the unique HR needs of your organization with options for
                all kinds of workplaces.
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8"></div>
        </Row>
        <Row>
          <div className="col-sm-12 col-md-6 col-lg-4">
            {/* <div className='payment-card'>
            <label className="label-radio">
              <input
                type="radio"
                value="Credit Card"
                checked={selectedOption === "Credit Card"}
                onChange={handleOptionChange}
              />
              Credit Card
            </label>
            <img src={Visa} alt="Visa" className=''/>
            </div> */}
            <div className='payment-card'>
            <label className="label-radio">
              <input
                type="radio"
                value="PayPal"
                checked={selectedOption === "PayPal"}
                onChange={handleOptionChange}
              />
              PayPal
            </label>
            <img src={Paypal} alt="Paypal" className=''/>
            </div>
            {/* <div className='payment-card'>
            <label className="label-radio">
              <input
                type="radio"
                value="AliPay"
                checked={selectedOption === "AliPay"}
                onChange={handleOptionChange}
              />
              AliPay
            </label>
            <img src={Alipay} alt="Alipay" className=''/>
            </div> */}

          </div>
          <div className=" col-sm-12 col-md-6 col-lg-8">
            <div>
                <div className='package-title'>Premium Package - 48 Months Plan</div>
                <div className='plan-details'>
                    <div className='flex-div'>
                    <div>Plan Discount</div>
                    <div className='package-price discount' >-$100</div>
                    </div>

                    <div className='flex-div'>
                    <div>Taxes & Fees</div>
                    <div className='package-price'>$45.2</div>
                    </div>
                </div>
                <div className='flex-div'>
                    <div className='package-title'>TOTAL</div>
                    <div className='total-price'>$245.2</div>
                </div>
                <div>
                    <button className='btn-payment'>Submit Secure Payment</button>
                </div>
                <div className='package-text'>By checking out you agree with our <a>Terms of Service</a>. We will process your personal data for the fulfillment of your order and other purposes as per our <a>Privacy Policy</a>. You can cancel recurring payments at any time.</div>

            </div>
          </div>
        </Row>
      </Container>
    );
}

export default Payment
