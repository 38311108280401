import React, { useState, useRef, useEffect } from 'react';
import "./Report.css";
import { AddBtn, NotePopUp, Note, ReminderPopUp, AddReport } from '../../../../Components';
import addBtn from "../../../../assets/images/svg/addbtn.svg"
import Modal from "react-modal";

const Report = ({ url, date, textDate, title, textTitle, title2, para, showButton, reportCount, noteCount, reminderCount, titleLink, docName, federalRegulation , onRefresh, isSmallScreen }) => {

  const [showAddNote, setShowAddNote] = useState(false);
  const [showAddReminder, setShowAddReminder] = useState(false);
  const [showAmendmentsBtns, setShowAmendmentsBtns] = useState(false);
  // const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const notePopUpRef = useRef(null);
  const reminderPopUpRef = useRef(null);
  const dateTimePickerRef = useRef(null);
  const showBtnsRef = useRef(null);  // Ref for the `show-btns` container
  const amendmentsBtnsRef = useRef(null);  // Ref for the `amendments-btns` container

  const handleAddNoteClick = () => {
    setShowAddNote(!showAddNote);
    setShowAddReminder(false); // Close the reminder pop-up if it was open
  };

  const handleAddReminderClick = () => {
    setShowAddReminder(!showAddReminder);
    setShowAddNote(false); // Close the note pop-up if it was open
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  }
  const handleShowBtnsClick = () => {
    if (isSmallScreen) {
      setShowAmendmentsBtns(!showAmendmentsBtns);
    }
  };

  const handleNoteClickOutside = (event) => {
    if (notePopUpRef.current && !notePopUpRef.current.contains(event.target)) {
      setShowAddNote(false);
    }
  };

  const handleClickOutsideReminder = (event) => {
    // Check if the click happened inside the ReminderPopUp
    const isClickInsideReminderPopup = reminderPopUpRef.current && reminderPopUpRef.current.contains(event.target);

    // Check if the click happened inside the DateTimePicker input wrapper
    const isClickInsideDatePicker = dateTimePickerRef.current && dateTimePickerRef.current.contains(event.target);

    // Check if the click happened inside the DateTimePicker's popper (dropdown)
    const isClickInsideDatePickerPopper = document.querySelector('[role="dialog"]')?.contains(event.target);

    // If the click is outside of the ReminderPopUp, DateTimePicker input, and DateTimePicker popper, close the ReminderPopUp
    if (!isClickInsideReminderPopup && !isClickInsideDatePicker && !isClickInsideDatePickerPopper) {
      setShowAddReminder(false);
    }
  };

  const handleClickOutside = (event) => {

        const isClickInsideDatePickerPopper = document.querySelector('[role="dialog"]')?.contains(event.target);
    if (
      showBtnsRef.current && !showBtnsRef.current.contains(event.target )  && !isClickInsideDatePickerPopper
    ) {
      setShowAmendmentsBtns(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutsideReminder);
    return () => {
      window.removeEventListener("mousedown", handleClickOutsideReminder);
    };
  }, []);




  
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    window.addEventListener("mousedown", handleNoteClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleNoteClickOutside);
    };
  }, []);



  return (
    <div className="amendments-content">
      {isSmallScreen && (
        <div className="show-btns" onClick={handleShowBtnsClick}></div>
      )}
      <Note
        titleLink={titleLink}
        url={url}
        date={date}
        textDate={textDate}
        title={title}
        textTitle={textTitle}
        title2={title2}
        para={para}
        showButton={showButton}
        docName={federalRegulation}
      />
      {(isSmallScreen && showAmendmentsBtns) || !isSmallScreen ? (
        <div>
          <div className="amendments-btns" ref={showBtnsRef}>
            <div
              ref={notePopUpRef}
              className={`NotePopUp ${showAddNote ? "active" : ""}`}
            >
              <NotePopUp
                url={url}
                count={"4"}
                isOpen={showAddNote}
                onCancel={handleAddNoteClick}
                docName={federalRegulation}
                onRefresh={onRefresh}
              />
            </div>
            <div
              ref={reminderPopUpRef}
              className={`ReminderPopUp ${showAddReminder ? "active" : ""}`}
            >
              <ReminderPopUp
                url={url}
                count={"4"}
                docName={federalRegulation}
                isOpen={showAddReminder}
                onCancel={handleAddReminderClick}
                onRefresh={onRefresh}
                dateTimePickerRef={dateTimePickerRef} // Pass ref to ReminderPopUp
              />
            </div>
            <div onClick={togglePopup}>
              <AddBtn name={"Add to Report"} count={reportCount} img={addBtn} />
            </div>
            <div onClick={handleAddNoteClick}>
              <AddBtn name={"Add Note"} count={noteCount} img={addBtn} />
            </div>
            <div onClick={handleAddReminderClick}>
              <AddBtn
                name={"Add Reminder"}
                count={reminderCount}
                img={addBtn}
              />
            </div>
          </div>
        </div>
      ) : null}
      <Modal isOpen={showPopup} onRequestClose={togglePopup}>
        <AddReport
          cancelBtn={togglePopup}
          url={url}
          regulation={federalRegulation}
        />
      </Modal>
    </div>
  );
}

export default Report;