import React, { useState } from "react";
import "./ProfileTap.css";
import { Report } from "../../index";
import Chevron from "../../../../assets/images/svg/Month Chevron.svg";
import TabIcon from "../../../../assets/images/svg/subscribe-icon.svg";

const ProfileTap = ({ svg , title , text}) => {
  return (
    <div className="">
      <div
        class="profile-tab profile-tab-flex my-account"
        style={{ position: "relative" }}
        data-tab="profile"
      >
        {svg}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="41"
          viewBox="0 0 40 41"
          fill="none"
        >
          <circle opacity="0.05" cx="20" cy="20.8408" r="20" fill="#2DB0D8"  class="circle-icon"/>
          <path
            d="M12 21C12 25.4184 15.5816 29 20 29C24.4184 29 28 25.4184 28 21C28 16.5816 24.4184 13 20 13C15.5816 13 12 16.5816 12 21Z"
            stroke="#2DB0D8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.7999 13.04C20.7999 13.04 23.1999 16.2 23.1999 21C23.1999 25.8 20.7999 28.96 20.7999 28.96M19.1999 28.96C19.1999 28.96 16.7999 25.8 16.7999 21C16.7999 16.2 19.1999 13.04 19.1999 13.04M12.5039 23.8H27.4959M12.5039 18.2H27.4959"
            stroke="#2DB0D8"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg> */}
        {/* <img src={TabIcon} class="profile-icon" /> */}
        <div class="tab-flex">
          <div class="tab-title">{title}</div>
          <div class="tab-text">{text}</div>
        </div>
        <div class="chevron-div">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="14"
            viewBox="0 0 7 14"
            fill="none"
			
          >
            <path
              d="M1.06641 12.2463C1.3584 12.2463 1.59326 12.1447 1.79639 11.9416L6.57617 7.28239C6.84912 7.00945 6.96973 6.74284 6.97607 6.40642C6.97607 6.07634 6.84912 5.79704 6.57617 5.53044L1.80273 0.864914C1.59326 0.668137 1.35205 0.566574 1.06641 0.566574C0.482422 0.566574 0 1.0363 0 1.62029C0 1.91228 0.126953 2.17888 0.342773 2.40105L4.48779 6.41277L0.342773 10.4118C0.120605 10.634 0 10.9006 0 11.1926C0 11.7702 0.482422 12.2463 1.06641 12.2463Z"
              fill="white"
			  className="chevron"
            />
          </svg>
          {/* <img src={Chevron} /> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileTap;
