import * as React from 'react';
import { useState, useEffect } from "react";
import "./EmployeeList.css";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddEmployeeModal from '../AddEmployeeModal/AddEmployeeModal';
import { Empty } from 'antd';
import axios from 'axios';
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';


export default function EmployeeList({ template, rowId, url }) {
  const employeeProfileData = template?.data?.content?.employees?.objects || [];
  let paginationData = template?.data?.content?.employees?.pagination || {};
  const [gridKey, setGridKey] = useState(0); // Add a key state

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(paginationData.metadata?.current_page - 1 || 0); // API pages are typically 1-indexed
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false); // State for modal visibility
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(paginationData.metadata?.total_pages || 1);
  const [totalData, setTotalData] = useState(paginationData.metadata?.total_data || 1);
  const [selectionModel, setSelectionModel] = React.useState([]);

  
  // const [loading, setLoading] = useState(true);


  useEffect(() => {
    fetchData(0, 10); // Fetch data when the component mounts
  }, []);

  useEffect(() => {
    fetchData(0, 10); // Fetch data when totalData changes
    setGridKey((prevKey) => prevKey + 1); // Update the key to force re-render
  }, [totalData]); // Run this effect when totalData changes

  useEffect(() => {
    setRows(employeeProfileData.map((employee, index) => {
      return {
        id: index + 1, // Assign a unique id to each row, but it's not displayed in the grid
        img: (
          <Avatar
            src={employee.user_image ? url + employee.user_image : employee.full_name}
            alt={employee.full_name}
            sx={{ width: 40, height: 40 }}
          />
        ),
        name: `${employee.first_name} ${employee.last_name}`,
        email : `${employee.name}`,
        status: employee.enabled ? <div className='status enable'>Active</div> : <div className='status disable'>InActive</div>,

      };
    }));
  }, [employeeProfileData, url]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    fetchData(newPage, rowsPerPage);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSave = (newEmployee) => {
    console.log("New Employee Data:", newEmployee);
  };




    const fetchData = async (page, rowsPerPage) => {
    const offset = page * rowsPerPage;
    const limit = rowsPerPage;
      const apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.employee?start=${offset}&limit=${limit}`;
      try {
        const apiKey = Cookies.get("api_key");
        const apiSecret = Cookies.get("api_secret");
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${apiKey}:${apiSecret}`,
          },
        });
        console.log("response", response);
        if (response.data && response.data.status_code === 200) {
          const updatedEmployeeProfileData =
          response.data?.result?.objects|| [];

          setRows(
            response.data?.result?.objects.map((employee, index) => {
              return {
                id: index + 1, // Assign a unique id to each row
                img: (
                  <Avatar
                    src={url + employee.user_image}
                    alt={employee.full_name}
                    sx={{ width: 40, height: 40 }}
                  />
                ),
                name: `${employee.first_name} ${employee.last_name}`,
                email : `${employee.name}`,
                status: employee.enabled ? "Active" : "Inactive",
              };
            })
          );

          setTotalPages(response.data.result?.pagination?.metadata?.total_pages || 1);
          setTotalData(response.data.result?.pagination?.metadata?.total_data || 1);
          console.log("total_pages",response.data.result?.pagination?.metadata?.total_pages)
          console.log("response",response)

          console.log("total_data",response.data.result?.pagination?.metadata?.total_data);
           console.log("paginationData",response.data.result?.pagination);
        } else {
        }
      } catch (error) {
        toast.error("Failed to fetch data. Please try again..");
      }
    };


    const handleDelete = async (selectedEmails) => {
      console.log("Delete employees with emails:", selectedEmails);
      handleClose();
    
      // Retrieve the API credentials from cookies
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
    
      // Prepare the data payload
      const data = {
        "users": selectedEmails
      };
    
      console.log("Data:", data);
    
      try {
        // Construct the API URL
        const apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.employee`;
    
        // Make the DELETE request
        const response = await axios({
          method: 'DELETE',
          url: apiUrl,
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${apiKey}:${apiSecret}`,
          },
          data: data
        });
    
        // Check the status of the response
        if (response.status === 200) {
          toast.success("Employees deleted successfully.");
          // Optionally, reload data after successful deletion
          fetchData(0, 10);
        } else {
          console.log("Response status code:", response.status);
          toast.error(response.data.message || "Error deleting employees. Please try again.");
        }
    
      } catch (error) {
        // Improved error handling
        console.error("Error deleting employees:", error);
        toast.error(error.response?.data?.message || "An error occurred while deleting employees. Please try again.");
      }
    };
    
  const columns = [
    {
      field: 'img', headerName: '', width: 80, renderCell: (params) => params.value, sortable: false,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    { field: 'name', headerName: 'Employee Name', width: 200, editable: true, renderCell: (params) => params.value },
    { field: 'status', headerName: 'Status', width: 120, renderCell: (params) => params.value },
    {
      field: 'action',
      headerName: '',
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => <ActionMenu rowId={params.row.id} url={url} fetchData={fetchData} handleDelete={()=>handleDelete([params.row.email])}/>,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: "delete",
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <IconButton
            onClick={() => {
              console.log(selectionModel);
              const selectedIDs = new Set(selectionModel);
              console.log(selectedIDs)
              const selectedEmails = rows
              .filter(row => selectedIDs.has(row.id)) // Filter rows based on selected IDs
              .map(row => row.email); // Extract emails from filtered rows
    
            console.log("users",selectedEmails);
            handleDelete(selectedEmails);
              // you can call an API to delete the selected IDs
              // and get the latest results after the deletion
              // then call setRows() to update the data locally here
              // setRows((r) => r.filter((x) => !selectedIDs.has(x.id)));
            }}
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ];
  return (
    <div className='employee-list-container'>
      <div className='note-employee'>Number of employees you can add: <span style={{ color: "#2DB0D8" }}>20 Employee</span> </div>
      <button className='btn-add-employee' onClick={handleOpen}>Add New Employee +</button>
      <div className='employee-list-content'>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid 
            key={gridKey} // Add a key prop to force re-render
            rows={rows} // Use your dynamically fetched rows here
            columns={columns} // Use your defined columns with action buttons
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              console.log(ids)
              setSelectionModel(ids);
            }}
            disableRowSelectionOnClick
            rowCount={totalData}
          />
        </Box>
      </div>

      <AddEmployeeModal url={url} open={open} handleClose={handleClose} handleSave={handleSave} fetchData={fetchData}/>

    </div>
  );
}

function ActionMenu({ rowId,url , email ,setRows ,fetchData,handleDelete}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log("setAnchorEl",setAnchorEl)
  };

  const handleEdit = () => {
    console.log("Edit employee with id:", rowId);
    handleClose();
  };





  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock={true}
      >
        {/* <MenuItem onClick={handleEdit}>
          Edit
        </MenuItem> */}
        <MenuItem onClick={handleDelete} >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
