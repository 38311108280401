import React, { useState, useEffect } from 'react';
import "./CheckLabel.css";
import { UnderDevelopment } from "../index";
import Modal from 'react-modal';

const CheckLabel = ({ name, count, onCheckboxChange, checked }) => {

    const [isChecked, setIsChecked] = useState(checked??false);


    const handleCheckboxChange = () => {
      const newValue = !isChecked; // Toggle the value of isChecked
      setIsChecked(newValue);
      onCheckboxChange(name, newValue); // Pass the checkbox name and value to the parent component
    };


    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);
    


    // const [showPopup, setShowPopup] = useState(false);
    // const [isChecked, setIsChecked] = useState(false);

    // const togglePopup = () => {
    //     setShowPopup(!showPopup);
    // };

    // const handleCheckboxChange = () => {
    //     if (!isChecked) {
    //         togglePopup();
    //     }
    //     setIsChecked(!isChecked);
    // };
    return (
        <div className='check-label'>
            <input 
                type="checkbox" 
                className='check-label-input' 
                checked={isChecked}
                onChange={handleCheckboxChange} 
            />
            <label>{name} {count}</label>
            {/* <Modal
                isOpen={showPopup}
                onRequestClose={togglePopup}
                contentLabel='Select Filter Popup'
            >
                <UnderDevelopment btnClick={togglePopup} />
            </Modal> */}
        </div>
    );
}

export default CheckLabel;