import React, { useState } from 'react';
import "./Chat.css";
import message from "../../../../assets/images/chatbot/message.svg";
import { MenuDots } from '../../../../Components';
import deleteIcon from "../../../../assets/images/chatbot/delete.svg";
import shareIcon from "../../../../assets/images/chatbot/share.svg";
import Modal from "react-modal";

const Chat = ({ title, onClick, onDelete, onShare, isActive, isDarkMode }) => {

    const [showPopup, setShowPopup] = useState(false);

    const handleDelete = () => {
        setShowPopup(true);
    };

    const cancelDelete = () => {
        setShowPopup(false);
    };

    const menuChildren = [
        <div className={`chat-props ${isDarkMode ? 'dark-mode' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24" class="icon-xl-heavy"><path d="M15.673 3.913a3.121 3.121 0 1 1 4.414 4.414l-5.937 5.937a5 5 0 0 1-2.828 1.415l-2.18.31a1 1 0 0 1-1.132-1.13l.311-2.18A5 5 0 0 1 9.736 9.85zm3 1.414a1.12 1.12 0 0 0-1.586 0l-5.937 5.937a3 3 0 0 0-.849 1.697l-.123.86.86-.122a3 3 0 0 0 1.698-.849l5.937-5.937a1.12 1.12 0 0 0 0-1.586M11 4A1 1 0 0 1 10 5c-.998 0-1.702.008-2.253.06-.54.052-.862.141-1.109.267a3 3 0 0 0-1.311 1.311c-.134.263-.226.611-.276 1.216C5.001 8.471 5 9.264 5 10.4v3.2c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 0 0 1.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h3.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 0 0 1.311-1.311c.126-.247.215-.569.266-1.108.053-.552.06-1.256.06-2.255a1 1 0 1 1 2 .002c0 .978-.006 1.78-.069 2.442-.064.673-.192 1.27-.475 1.827a5 5 0 0 1-2.185 2.185c-.592.302-1.232.428-1.961.487C15.6 21 14.727 21 13.643 21h-3.286c-1.084 0-1.958 0-2.666-.058-.728-.06-1.369-.185-1.96-.487a5 5 0 0 1-2.186-2.185c-.302-.592-.428-1.233-.487-1.961C3 15.6 3 14.727 3 13.643v-3.286c0-1.084 0-1.958.058-2.666.06-.729.185-1.369.487-1.961A5 5 0 0 1 5.73 3.545c.556-.284 1.154-.411 1.827-.475C8.22 3.007 9.021 3 10 3A1 1 0 0 1 11 4"></path></svg>
            <h1 className='chat-prop-title'>Rename</h1>
        </div>,
        <div className={`chat-props ${isDarkMode ? 'dark-mode' : ''}`} onClick={onShare}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="h-5 w-5 shrink-0"><path fill="#ccc" fill-rule="evenodd" d="M11.293 3.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414L13 6.414V15a1 1 0 1 1-2 0V6.414L8.707 8.707a1 1 0 0 1-1.414-1.414zM4 14a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3a1 1 0 1 1 2 0v3a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-3a1 1 0 0 1 1-1" clip-rule="evenodd"></path></svg>
            <h1 className='chat-prop-title'>Share</h1>
        </div>,
        <div className='chat-props' onClick={handleDelete}>
            <img className='chat-icon' src={deleteIcon} />
            <h1 className='chat-prop-title chat-delete'>Delete</h1>
        </div>
    ];

    return (
        <div className={`chat-message ${isActive ? 'active-chat' : ''} ${isDarkMode ? 'dark-mode' : ''}`} >
            <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="chat_bubble" clipPath="url(#clip0_1515_1037)">
                    <path id="Vector" d="M16.6665 1.66669H3.33317C2.4165 1.66669 1.6665 2.41669 1.6665 3.33335V18.3334L4.99984 15H16.6665C17.5832 15 18.3332 14.25 18.3332 13.3334V3.33335C18.3332 2.41669 17.5832 1.66669 16.6665 1.66669ZM16.6665 13.3334H4.99984L3.33317 15V3.33335H16.6665V13.3334Z" fill="#474747"/>
                </g>
                <defs>
                    <clipPath id="clip0_1515_1037">
                        <rect width="20" height="20" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            <h1 className='chat-message-title' onClick={onClick}>{title}</h1>
            <div className='chat-dots'>
                <MenuDots
                    menuClass={"chatbot-menu"}
                    options={menuChildren}
                    bgColor={isDarkMode ? "#2f2f2f" : "#fff"}
                />
            </div>

            <Modal 
                isOpen={showPopup} 
                onRequestClose={cancelDelete}
                overlayClassName={isDarkMode ? 'custom-modal-overlay' : ''}
            >
                <div className={`confirm-delete-container ${isDarkMode ? 'dark-mode' : ''}`}>
                    <div className='confirm-delete-content'>
                        <img className='delete-img' src={deleteIcon} />
                        <h1 className='delete-title'>Are you sure?</h1>
                    </div>
                    <div className='confirm-btns'>
                        <button className='cancle-delete' onClick={cancelDelete}>Cancel</button>
                        <button className='confirm-delete' onClick={() => { 
                            onDelete(); 
                            cancelDelete();
                        }}>Delete</button>
                    </div>
                </div>  
            </Modal>
        </div>
    )
}

export default Chat;
