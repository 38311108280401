import React from 'react'
import "./RemoveBtn.css"
import dash from "../../assets/images/svg/dash.svg"

const RemoveBtn = ({ name, count }) => {
    return (
      <div className="remove-btn">
        {name !== "Expired" && (
          <div className="remove-btn-image">
            <img src={dash} />
          </div>
        )}
        <h1
          className={`remove-btn-title ${
            name === "Expired" ? "expired-title" : "remove-btn-title"
          }`}
        >
          {name}
        </h1>
        {count && <h1 className="remove-btn-count">({count})</h1>}
      </div>
    );
}

export default RemoveBtn
