import React from 'react'
import "./Hero.css"
import { Container, Row, Col } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const AboutHero = ({ mainTitle ,title, para }) => {

    return (
      <div className="hero-img">
        <Container>
          <div className="about-div">
            <div className="about-link">
              <Link to="/home" className="about-link">
                <span>{mainTitle}</span>
              </Link>{" "}
              {title}
            </div>
            <div className="about-title">{para}</div>
          </div>
        </Container>
      </div>
    );
}

export default AboutHero
