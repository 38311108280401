import React, { useState, useEffect } from 'react';
import { formatDistanceStrict } from 'date-fns';
import "./Notification.css";
import clock from "../../assets/images/svg/clockNotification.svg";
import regulations from "../../assets/images/svg/amendments.svg";

const Notification = ({ desc, type, regulationLink, creationTime, isReading, url }) => {
    const maxLength = 100;
    const [isExpanded, setIsExpanded] = useState(false);

    const truncatedDesc = desc.length > maxLength && !isExpanded 
        ? `${desc.substring(0, maxLength)}...` 
        : desc;

    const handleClick = (e) => {
        if (type !== "Federal Regulation") {
            e.preventDefault(); 
        }
    };

    const handleReadMoreClick = (e) => {
        e.preventDefault();
        setIsExpanded(!isExpanded);
    };

    const formattedTime = formatDistanceStrict(new Date(creationTime), new Date(), { addSuffix: false });


    return (
        <a 
            href={regulationLink || '#'} 
            className="notification-content-container" 
            style={{
                cursor: type === "Federal Regulation" ? 'pointer' : 'default', 
                pointerEvents: type === "Federal Regulation" ? 'auto' : 'none', 
            }}
            onClick={handleClick}
        >
            <div className="notification-header">
                <div className="notification-header-left">
                    <div className="notification-header-title">
                        <img src={ type === "Federal Regulation" ? regulations : clock } alt="Clock icon" />
                        <span>{type}</span>
                    </div>
                </div>
                <div className="notification-header-right">
                    <div className="notification-header-read-status" style={{ backgroundColor: isReading ? "#E6E6E6" : "#EC5252" }}></div>
                    <div className="notification-header-time">
                        <span className="notification-time">{formattedTime}</span>
                    </div>
                </div>
            </div>
            <div className="notification-content">
                {truncatedDesc}
                {desc.length > maxLength && !isExpanded && type !== "Federal Regulation" && (
                    <a href="#" onClick={handleReadMoreClick} className="read-more">
                        Read more
                    </a>
                )}
            </div>
        </a>
    );
};

export default Notification;
